<template>
  <div class="card" :class="{ card2: isMobile, margin_card: !isMobile }">
    <div class="card-body">
      <div class="row">
        <div class="col-lg-12 col-md-12 col-12 col-sm-12 col-xl-12">
          <h6
            style="
              font-size: 14px;
              font-weight: bold;
              margin-bottom: 20px;
              line-height: 15px;
              text-align: center;
            "
          >
            ¡Llena los datos de tu Cliente Nuevo para Xtrim! Ganarás $20 por
            cada uno!
          </h6>

          <button
            @click="registerReferido"
            class="btn btn-primary btn-sm"
            style="width: 100%"
          >
            Registrar interesado en XTRIM
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: ["isMobile"],
  data() {
    return {};
  },
  computed: {},
  watch: {},
  mounted() {},
  methods: {
    registerReferido() {
      this.$eventBus.emit("showModalVenta");
    },
  },
};
</script>

<style scope></style>
