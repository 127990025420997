<template>
  <listado-ventas
    :key="componentKeyListadoVentas"
    :user-id="userId"
    :token-auth="tokenAuth"
  />
</template>

<script>
/* eslint-disable */

import ListadoVentas from "./ListadoVentas.vue";

export default {
  components: {
    ListadoVentas,
  },
  props: [],
  data() {
    let data = this.$store.state.user;
    return {
      componentKeyListadoVentas: 400,
      userId: data.userId,
      role: data.role,
      tokenAuth: this.$store.state.tokenAuth,
    };
  },
  computed: {},
  watch: {},
  mounted() {
    this.$eventBus.on("reiniciarListadoVentas", () => {
      this.componentKeyListadoVentas += 1;
    });
  },
  methods: {
    refresh() {
      this.$eventBus.emit("reiniciarListadoVentas");
    },
  },
};
</script>

<style lang="scss"></style>
