<template>
  <div class="container">
    <div class="row justify-content-end mt-2">
      <!-- <div class="col-4"> -->
      <a href="/login" class="custom-button">
        <div class="custom-button-icon">
          <i class="fas fa-user"></i>
        </div>
        <div class="custom-button-text">
          XtrimPro <small>Iniciar sesión</small>
        </div>
      </a>
      <!-- </div> -->
    </div>
  </div>
</template>

<script>
/* eslint-disable */

export default {
  name: "Ingresar-Landing",
  components: {},

  data() {
    return {
      loading: true,
      isMobile: this.$isMobile,
    };
  },
  computed: {},
  watch: {},

  activated() {},
  mounted() {
    if (this.isMobile) {
      this.$eventBus.emit("activeShowMenu");
    }
  },
  methods: {},
};
</script>

<style scoped>
.custom-button {
  display: flex;
  align-items: center;
  background-color: white; /* Púrpura #800080 */
  /* Color púrpura */
  border: 3px solid #ffd700;
  /* Contorno amarillo */
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  padding: 10px 20px;
  color: white;
  font-family: Arial, sans-serif;
  font-weight: bold;
  position: relative;
  overflow: hidden;
}

.custom-button-icon {
  background-color: #800080; /* Púrpura #800080 */
  color: white;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  flex-shrink: 0;
}

.custom-button-text {
  color: #800080;
  font-size: 10px;
}

.custom-button-text small {
  display: block;
  font-size: 8px;
  color: #800080;
  font-weight: normal;
}
</style>
