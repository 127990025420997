<template>
  <div>
    <VueFinalModal
      v-model="showModal"
      :esc-to-close="true"
      @closed="closed"
      class="modal-container"
      content-class="modal-content"
    >
      <div class="modal__content">
        <form role="form" autocomplete="off" @submit.prevent="onSubmit">
          <div class="row" v-if="!notSupport">
            <div
              class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
              style="margin-bottom: 10px"
            >
              <label
                for="example-text-input"
                class="form-control-label"
                style="font-weight: bold; font-size: 12px"
                >Dirección de su domicilio (*):</label
              >

              <GMapAutocomplete
                :value="autocomplete"
                placeholder="Dirección domiciliaria"
                @place_changed="getAddressData($event)"
                required
                :readonly="isDisabled"
                class="form-control col-lg-12"
              >
              </GMapAutocomplete>
            </div>

            <div
              class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
              style="margin-bottom: 10px"
            >
              <label
                for="example-text-input"
                class="form-control-label"
                style="font-weight: bold; font-size: 12px"
                >Indique la ubicación de su domicilio (*):</label
              >

              <GMapMap
                @click="mark"
                :center="center"
                :zoom="16"
                map-type-id="terrain"
                style="width: 100%; height: 250px"
                :options="{
                  zoomControl: true,
                  mapTypeControl: true,
                  scaleControl: true,
                  streetViewControl: true,
                  rotateControl: true,
                  fullscreenControl: true,
                  disableDefaultUi: true,
                }"
              >
                <GMapMarker
                  :key="index"
                  v-for="(m, index) in markers"
                  :position="m.position"
                  :clickable="true"
                  :draggable="true"
                  @click="center = m.position"
                  @dragend="handleMarkerDrag"
                />
              </GMapMap>
            </div>

            <div
              class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
              style="text-align: right"
            >
              <button
                type="submit"
                id="btn_solicitar"
                class="btn btn-primary"
                :disabled="isDisabled"
              >
                Completar venta
              </button>
            </div>
          </div>

          <div v-else class="row">
            <div
              class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
              align="center"
            >
              <svg
                width="100"
                height="100"
                viewBox="-20 0 190 190"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M38.155 140.475 48.988 62.1108l43.881 4.946 18.568 23.955-8.041 57.1092L38.155 140.475zM84.013 94.0018 88.827 71.8068l-34.781-3.5-9.854 67.1502L98.335 142.084l6.542-45.2752-20.864-2.807zM59.771 123.595C59.394 123.099 56.05 120.299 55.421 119.433 64.32 109.522 86.05 109.645 92.085 122.757 91.08 123.128 86.59 125.072 85.71 125.567 83.192 118.25 68.445 115.942 59.771 123.595zM76.503 96.4988 72.837 99.2588l-5.515-6.642L59.815 96.6468l-3.029-5.069L63.615 88.1508l-4.526-5.452L64.589 79.0188 68.979 85.4578 76.798 81.5328 79.154 86.2638l-7.047 3.783 4.396 6.452z"
                  fill="#4a4a4a"
                />
              </svg>

              <div
                class="alert alert-primary"
                role="alert"
                style="margin: 0px; font-size: 12px"
              >
                <p style="text-align: center; font-size: 14px; margin: 0px">
                  Tu navegador no soporta la API de geolicación. Por favor
                  intente con el navegador Chrome, Brave, Mozilla u otro.
                </p>
              </div>
            </div>
          </div>
        </form>
      </div>
    </VueFinalModal>
  </div>
</template>

<script>
/*eslint-disable */

import { VueFinalModal } from "vue-final-modal";
import "vue-final-modal/style.css";

export default {
  name: "ModalCompletarVenta",
  components: {
    VueFinalModal,
  },
  props: ["idVenta", "tokenAuth", "geocoder"],
  data() {
    return {
      showModal: false,
      isDisabled: false,
      Ubication: [],
      center: {},
      markers: [],
      geocoder2: this.geocoder,
      plusCode: "",
      notSupport: false,
      loadMap: true,
      city: "",
      autocomplete: "",
    };
  },
  activated() {},
  methods: {
    getAddressData(request) {
      this.autocomplete = request.formatted_address;

      let ubicacion = request.geometry.location;
      this.center = {
        lat: Number(request.geometry.location.lat()),
        lng: Number(request.geometry.location.lng()),
      };

      this.markers = [
        {
          position: ubicacion,
        }, // Along list of clusters
      ];

      this.geocoder2
        .geocode({ location: this.center })
        .then((response) => {
          if (response.results[0]) {
            this.plusCode = response.results[0].formatted_address;
            this.autocomplete = response.results[1].formatted_address;
            this.city = response.results[0].address_components[2].long_name;
          }
        })
        .catch((e) => console.log("Geocoder failed due to: " + e));
    },

    handleMarkerDrag(e) {
      this.center = {
        lat: Number(e.latLng.lat()),
        lng: Number(e.latLng.lng()),
      };

      this.geocoder2
        .geocode({ location: this.center })
        .then((response) => {
          if (response.results[0]) {
            this.plusCode = response.results[0].formatted_address;
            this.autocomplete = response.results[1].formatted_address;
            this.city = response.results[0].address_components[2].long_name;
          }
        })
        .catch((e) => console.log("Geocoder failed due to: " + e));
    },

    mark(event) {
      this.center = {
        lat: Number(event.latLng.lat()),
        lng: Number(event.latLng.lng()),
      };

      this.markers = [
        {
          position: {
            lat: Number(event.latLng.lat()),
            lng: Number(event.latLng.lng()),
          },
        }, // Along list of clusters
      ];

      this.geocoder2
        .geocode({ location: this.center })
        .then((response) => {
          if (response.results[0]) {
            this.plusCode = response.results[0].formatted_address;
            this.city = response.results[0].address_components[2].long_name;
            this.autocomplete = response.results[1].formatted_address;
          }
        })
        .catch((e) => console.log("Geocoder failed due to: " + e));
    },

    closed() {
      this.$eventBus.emit("reiniciarModalCompletarVenta");
    },

    onSubmit(event) {
      event.preventDefault();

      this.isDisabled = true;

      const ubicacion_json = {
        idVenta: this.idVenta,
        address: this.autocomplete,
        latitude: this.center.lat,
        longitude: this.center.lng,
        plusCode: this.plusCode,
        city: this.city,
      };

      document.getElementById("btn_solicitar").innerHTML =
        '<i class="fas fa-spinner fa-pulse"></i>  Solicitando';

      this.$https
        .post("/ventas/finishVenta/", {
          tokenAuth: this.tokenAuth,
          ubicacion: ubicacion_json,
        })
        .then((response) => {
          document.getElementById("btn_solicitar").innerHTML =
            " Solicitar servicio";
          this.isDisabled = false;

          if (response.data.code == 200) {
            this.$toast.success(response.data.message, {
              position: "top-right",
              max: 10,
            });

            this.$eventBus.emit("reiniciarModalCompletarVenta");
            this.$eventBus.emit("reiniciarListadoVentas");
          } else {
            this.$toast.error(response.data.message, {
              position: "top-right",
              max: 10,
            });

            if (response.data.code == 401) {
              this.$store.dispatch("clearAllData");
              this.$store.dispatch("clearAllData");

              this.$router.push({ name: "auth-login" });
            }
          }
        })
        .catch((error) => {
          this.$toast.error("Ocurrio un error " + error, {
            position: "top-right",
            max: 10,
          });
        });
    },
  },
  mounted() {
    this.showModal = true;

    if (!("geolocation" in navigator)) {
      this.$toast.error(
        "La geolocalización no está soportado para este navegador",
        {
          position: "top-right",
          max: 10,
        },
      );

      this.notSupport = true;

      return;
    }

    // get position
    navigator.geolocation.getCurrentPosition(
      (pos) => {
        this.center = {
          lat: pos.coords.latitude,
          lng: pos.coords.longitude,
        };

        this.markers = [
          {
            position: {
              lat: pos.coords.latitude,
              lng: pos.coords.longitude,
            },
          }, // Along list of clusters
        ];

        this.geocoder2
          .geocode({ location: this.center })
          .then((response) => {
            if (response.results[0]) {
              this.plusCode = response.results[0].formatted_address;
              this.autocomplete = response.results[1].formatted_address;
              this.city = response.results[0].address_components[2].long_name;
            }
          })
          .catch((e) => console.log("Geocoder failed due to: " + e));
      },
      (err) => {
        this.$toast.error(err.message, {
          position: "top-right",
          timeout: 3010,
          closeOnClick: true,
          pauseOnFocusLoss: false,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.4,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      },
    );

    // this.cargarScript()
  },
};
</script>

<style scoped></style>
