<template>
  <div>
    <div class="row">
      <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <listado
          :key="componentKeyListadoReferidos"
          :user-id="userId"
          :token-auth="tokenAuth"
        />
      </div>
    </div>
  </div>
</template>

<script>
/*eslint-disable */

import Listado from "./Listado.vue";

export default {
  name: "IndexReferidos",
  components: {
    Listado,
  },
  data() {
    return {
      componentKeyListadoReferidos: 0,
      userId: this.$store.state.user.userId,
      tokenAuth: this.$store.state.tokenAuth,

      isMobile: this.$isMobile,
    };
  },
  activated() {
    if (this.isMobile) {
      this.$eventBus.emit("activeShowMenu");
    }
  },
  methods: {},
  mounted() {
    this.$eventBus.on("reiniciarListadoReferidos", () => {
      this.componentKeyListadoReferidos += 1;
    });
  },
};
</script>

<style scoped>
@import "datatables.net-dt";
@import "datatables.net-responsive-dt";
</style>
