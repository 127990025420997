/* eslint-disable */
import { createApp } from "vue";
import { getAnalytics } from "firebase/analytics";

import "maz-ui/css/main.css";
import "vue-select/dist/vue-select.css";
import App from "./App.vue";
import store from "./store";
import keys from "@/store/keys";
import router from "./router";
import API from "@/store/axios";
import VueGoogleMaps from "@fawmi/vue-google-maps";
import PinInput from "v-pin-input";
import ArgonDashboard from "./argon-dashboard";
import MazPhoneNumberInput from "maz-ui/components/MazPhoneNumberInput";
import LoadScript from "vue-plugin-load-script";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import Toaster from "@meforma/vue-toaster";
import { createVfm } from "vue-final-modal";
const vfm = createVfm();
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import { JSEncrypt } from "jsencrypt";
import { Skeletor } from "vue-skeletor";
import ReadMore from "vue-read-more";
import VLazyImage from "v-lazy-image";
import vSelect from "vue-select";
import "./registerServiceWorker";
import VueCleave from "vue-cleave-component";
import Emittery from "emittery";
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";

const appFirebase = firebase.initializeApp({
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
  measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID,
});

const analytics = getAnalytics(appFirebase);

const appCheck = initializeAppCheck(appFirebase, {
  provider: new ReCaptchaV3Provider(process.env.VUE_APP_RECAPTCHA_SITE_KEY), // Replace with your reCAPTCHA site key
  isTokenAutoRefreshEnabled: true, // Automatically refresh tokens
});

const auth = firebase.auth();

auth.setPersistence(firebase.auth.Auth.Persistence.LOCAL);

const encryptBackoffice = new JSEncrypt();
encryptBackoffice.setPublicKey(keys.publicKeyBackoffice);

const ua = navigator.userAgent;

let isMobile = false;

if (
  /Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
    ua,
  )
) {
  //es mobile
  isMobile = true;
} else {
  //es destopk
  isMobile = false;
}

const appInstance = createApp(App);
appInstance.component("MazPhoneNumberInput", MazPhoneNumberInput);
appInstance.config.globalProperties.$appFirebase = appFirebase;

appInstance.config.globalProperties.$analytics = analytics;
appInstance.config.globalProperties.$encryptBackoffice = encryptBackoffice;
appInstance.config.globalProperties.$https = API;
appInstance.config.globalProperties.$isMobile = isMobile;
appInstance.config.globalProperties.$eventBus = new Emittery();
appInstance.use(store);
appInstance.use(router);
appInstance.use(ReadMore);

appInstance.use(VueCleave);
appInstance.use(LoadScript);

appInstance.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
    libraries: "places,geocoding",
    v: "weekly",
  },
});

appInstance.component("v-select", vSelect);
appInstance.component("v-lazy-image", VLazyImage);
appInstance.component(Skeletor.name, Skeletor);
appInstance.use(VueSweetalert2);
appInstance
  .use(Toaster)
  .provide("toast", appInstance.config.globalProperties.$toast);
appInstance.use(vfm);
appInstance.use(PinInput);

appInstance.use(ArgonDashboard);
appInstance.provide("appCheck", appCheck);
appInstance.provide("https", API); // Provide $https globally
// appInstance.provide("store", store); // Provide $https globally
appInstance.provide("router", router); // Provide $https globally

appInstance.mount("#app");

let deferredPrompt = null;

window.addEventListener("beforeinstallprompt", (e) => {
  e.preventDefault();
  deferredPrompt = e; // Store globally
});

// Function to trigger the install prompt manually
export function promptPWAInstall() {
  if (deferredPrompt) {
    deferredPrompt.prompt();
    deferredPrompt.userChoice.then((choiceResult) => {
      console.log("User choice:", choiceResult.outcome);
      deferredPrompt = null; // Reset after use
    });
  }
}

export { analytics };
