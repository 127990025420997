<template>
  <div class="header-background">
    <!-- Fila para el botón en la parte superior derecha -->

    <!-- Fila de abajo para el logo y menú -->
    <div class="logo">
      <img
        src="../../assets/img/Landing/xtrim-pro-logo.png"
        alt="Logo XtrimPro"
      />
    </div>

    <nav class="menu d-flex justify-content-center">
      <div style="display: flex; justify-content: center; align-items: center">
        <!-- <a href="#inicio">INICIO</a></li> -->
        <a href="/login">REGÍSTRATE</a>
        <a href="#como-ganar">¿CÓMO GANAR?</a>
        <!-- <a href="#mapa">MAPA DE COBERTURA</a> -->
        <a href="#preguntas">PREGUNTAS FRECUENTES</a>
        <a href="#contacto">CONTÁCTANOS</a>
      </div>
    </nav>
    <div class="row justify-content-end align-items-center mb-2">
      <div class="mi-xtrimpro">
        <login-ingreso-desktop />
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import LoginIngresoDesktop from "./LoginDesktop.vue";

export default {
  name: "Landig-Desktop",
  components: {
    LoginIngresoDesktop,
  },

  data() {
    return {};
  },
  computed: {},
  watch: {},

  activated() {},
  mounted() {},
  methods: {},
};
</script>

<style scoped>
.landing-header {
  width: auto;
  height: auto;
  background: radial-gradient(circle at top left, #cbbdfe, transparent 50%),
    radial-gradient(circle at bottom right, #cbbdfe, transparent 40%), #fff;
}

.header-background {
  background-image: url("../../assets/img/Landing/bloque-superior-web.png");
  background-size: cover;
  background-position: center;
  padding: 10px 0 10px 20px;
  width: 100%;
  height: fit-content;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* Ajuste del botón "Ingresar" */
.mi-xtrimpro {
  text-align: right;
}

.mi-xtrimpro .btn-ingresar {
  background-color: white;
  color: #800080;
  border: none;
  border-radius: 5px;
  padding: 10px 15px;
  font-weight: bold;
  cursor: pointer;
}

.mi-xtrimpro .btn-ingresar:hover {
  background-color: #e0e0e0;
}

/* Logo */
.logo img {
  height: 80px;
}

/* Menú */
.menu div {
  list-style: none;
  display: flex;
  gap: 20px;
  margin: 0;
  padding: 0;
  justify-content: center;
}

/* Menú centrado */
.menu {
  text-align: center;
}

.menu div {
  position: relative;
}

.menu div a {
  text-decoration: none;
  color: white;
  font-weight: bold;
  position: relative;
  padding-bottom: 5px;
  transition: color 0.3s ease;
  height: fit-content;
}

.menu div a::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0%;
  height: 2px;
  background-color: yellow;
  transition: width 0.3s ease;
}

.menu div a:hover {
  color: yellow;
}

.menu div a:hover::after {
  width: 100%;
}
</style>
