<template>
  <div>
    <div class="row">
      <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <div class="card">
          <div class="card-body">
            <p style="font-size: 14px; text-align: justify">
              En mi calidad de usuario (en adelante “el usuario” o “usuario”) de
              esta plataforma (en adelante plataforma “PLANPRO”) de titularidad
              de PLANPROCAD S.A., reconozco expresamente que al utilizarla debo
              sujetarme en todo momento a lo aquí establecido. Al acceder o
              utilizar esta plataforma web,
              <b
                >acepto expresa, libre y voluntariamente los siguientes Términos
                y Condiciones de Uso.</b
              >
            </p>
            <p style="font-size: 14px; text-align: justify">
              Reconozco y acepto que la plataforma PLANPRO en la persona de su
              administrador o titular, se reserva el derecho de negarme el
              acceso a la misma en cualquier caso que considere apropiado, en
              particular si yo como usuario:
            </p>

            <div style="font-size: 14px; text-align: justify">
              <ol>
                <li style="font-size: 14px">Proporciono <b>datos falsos</b></li>
                <li style="font-size: 14px">
                  <b>Incumplo</b> estos Términos y Condiciones de Uso de
                  cualquier forma; y,
                </li>
                <li style="font-size: 14px">
                  Incumplo <b>cualquier normativa legal</b> aplicable respecto
                  del acceso o el uso de la presente plataforma.
                </li>
              </ol>
            </div>

            <p style="font-size: 14px; text-align: justify">
              Acepta libre, voluntaria y expresamente que soy el único
              responsable del contenido que cargue, publique, envíe por correo
              electrónico, transmita o de cualquier forma ponga a disposición a
              través de esta plataforma.
            </p>

            <p style="font-size: 14px; text-align: justify">
              Adicionalmente me obligo expresa, libre y voluntariamente a
              <b
                >no utilizar la presente plataforma en forma alguna que sirva
                directa o indirectamente para:</b
              >
            </p>

            <div style="font-size: 14px; text-align: justify">
              <ul>
                <li style="font-size: 14px">
                  Dañar a otras personas o animales de cualquier forma;
                </li>
                <li style="font-size: 14px">Realizar declaraciones falsas;</li>
                <li style="font-size: 14px">
                  Difundir de cualquier forma contenido que viole un derecho de
                  propiedad intelectual de terceros, incluyendo pero no limitado
                  a marcas, derechos de autor, secretos empresariales, patentes
                  y diseños industriales; y,
                </li>
                <li style="font-size: 14px">
                  Violar cualquier ley o norma jurídica nacional o
                  internacional.
                </li>
              </ul>
            </div>

            <p style="font-size: 14px; text-align: justify">
              Reconozco y acepto que la plataforma PLANPRO en la persona de su
              administrador o titular se reserva el derecho de modificar a su
              discreción los presentes términos y condiciones, comunicándome el
              particular, siempre de acuerdo con la ley ecuatoriana.
            </p>

            <p style="font-size: 14px; text-align: justify">
              <b>Datos Personales:</b> Autorizo expresamente a PLANPROCAD S.A. a
              utilizar mis datos personales entregados o generados por medio de
              mi utilización de la plataforma PLANPRO. Esta autorización incluye
              los siguientes usos:
            </p>

            <div style="font-size: 14px; text-align: justify">
              <ul>
                <li style="font-size: 14px">Acceso a datos;</li>
                <li style="font-size: 14px">Consulta de buró crediticio;</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/*eslint-disable */

export default {
  name: "Terminos",
  data() {
    return {
      isMobile: this.$isMobile,
    };
  },
  activated() {
    if (this.isMobile) {
      this.$eventBus.emit("inactiveShowMenu");
    }
  },
  methods: {},
  mounted() {},
};
</script>

<style scoped></style>
