<template>
  <div
    v-if="userExists"
    style="
      display: flex;
      flex-direction: column;
      width: 100%;
      margin: 0px;
      justify-content: center;
      align-items: center;
      min-height: 100vh;
      height: fit-content;
      gap: 24px;
    "
    class="menuContainer"
  >
    <img
      src="../../src/assets/img/Xtrim/Xtrim-Pro-logo.png"
      style="max-width: 300px; width: auto"
    />

    <div
      class="card col-11 col-sm-7 col-md-6 col-lg-5 col-xl-4 col-xxl-4"
      style="
        display: flex;
        flex-direction: column;
        padding: 25px;
        justify-content: center;
        align-items: center;
        gap: 12px;
      "
    >
      <p
        style="
          font-size: 20px;
          text-align: center;
          margin-bottom: 0px;
          line-height: 20px;
          color: #7d0c7e;
          font-weight: 600;
        "
      >
        ¡Hola! soy <span style="font-weight: 700">{{ displayName }}</span> y te
        invito a ganar un billete facilito como un Pro. <br />
        <br />¡Con XtrimPro!
      </p>
      <Skeletor v-if="loading" circle size="100" />

      <v-lazy-image
        v-else
        :src="photo"
        class="rounded-circle"
        @error="photo = '/img/user.svg'"
        style="background: white; width: 100px"
      />

      <div v-if="loading" style="width: 100%">
        <Skeletor
          :shimmer="true"
          height="10"
          width="100%"
          pill
          style="margin-bottom: 10px"
        />
        <Skeletor
          :shimmer="true"
          height="10"
          width="80%"
          pill
          style="margin-bottom: 10px"
        />
        <Skeletor
          :shimmer="true"
          height="10"
          width="60%"
          pill
          style="margin-bottom: 10px"
        />
        <Skeletor
          :shimmer="true"
          height="10"
          width="30%"
          pill
          style="margin-bottom: 10px"
        />
      </div>

      <div v-else class="bio-text">
        <p
          style="
            font-size: 16px;
            text-align: center;
            margin-bottom: 0px;
            line-height: 20px;
            font-weight: 600;
          "
        >
          {{ description }}
        </p>
      </div>

      <formulario :promo-code="promoCode" />
    </div>

    <white-footer-component />
  </div>

  <div v-else class="row" style="width: 100%; margin: 0px">
    <not-promotor />
  </div>
</template>

<script>
/*eslint-disable */

import NotPromotor from "./NotPromotor";
import Formulario from "./Formulario.vue";
import WhiteFooterComponent from "./Layouts/WhiteFooter.vue";

export default {
  name: "Invitacion",
  components: {
    NotPromotor,
    Formulario,
    WhiteFooterComponent,
  },
  data() {
    let promoCode = this.$route.params.promoCode;
    return {
      loading: true,
      isLogin: true,
      userExists: true,
      isMobile: this.$isMobile,
      displayName: "",
      photo: "",
      description: "",
      promoCode: promoCode,
      year: new Date().getFullYear(),
      link: "",
    };
  },
  methods: {
    capitalizeFirstLetter(str) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    },
    getInfo() {
      this.loading = true;
      this.$https
        .post("/links/getUserByPromoCode/", { promoCode: this.promoCode })
        .then((response) => {
          if (response.data.photo === "") {
            this.photo = "/img/user.svg";
          } else {
            this.photo = response.data.photo;
          }
          this.link = window.location.href;
          this.displayName =
            this.capitalizeFirstLetter(
              response.data.namesList[0].toLowerCase(),
            ) +
            " " +
            this.capitalizeFirstLetter(
              response.data.lastnamesList[0].toLowerCase(),
            );

          document.title = "XtrimPro - Link de referido de " + this.displayName;
          this.description =
            "Con Xtrim PRO, ganarás $20 por referir el servicio de internet Xtrim a tus conocidos y familiares. Es facilito: solo agrega a tus referidos y, cuando estos contraten el servicio, tu recibes tu billete. Invítalos con tu link a referir y ganar también, tu ganarás por los referidos de ellos. ¡A lo bien!";

          this.loading = false;
        })
        .catch((error) => {
          if (error.response.data.code == 404) {
            this.$toast.error(error.response.data.message, {
              position: "top-right",
              max: 10,
            });

            this.userExists = false;
          } else {
            this.$toast.error("Ocurrió un error inesperado", {
              position: "top-right",
              max: 10,
            });
          }
          // this.getInfo();
        });
    },
  },
  computed: {},
  mounted() {
    document.title = "XtrimPro - Link de referido ";
    this.getInfo();
  },
  created() {},
  beforeUnmount() {},
};
</script>

<style scoped>
.menuContainer {
  display: flex;
  min-height: 100vh;
  height: 100%;
  justify-content: center;
  align-items: center;
  min-height: calc(var(--vh, 1vh) * 100);
  margin: 0px;
  padding: 0;
  background-color: #7d0c7e !important;
  background: url("../../src/assets/img/Xtrim/register-background.png")
    no-repeat center center;
  background-size: cover;
  /* @media (max-width: 1199px) {
  } */
}
</style>
