<template>
  <VueFinalModal
    v-model="showTerminos"
    :esc-to-close="false"
    :click-to-close="false"
    :prevent-click="false"
    class="modal-container"
    content-class="modal-content4"
  >
    <span class="modal__title">Términos y condiciones de uso</span>
    <div class="modal__content">
      <p style="font-size: 12px; text-align: justify">
        En mi calidad de usuario (en adelante “el usuario” o “usuario”) de esta
        plataforma (en adelante plataforma “PLANPRO”) de titularidad de
        PLANPROCAD S.A., reconozco expresamente que al utilizarla debo sujetarme
        en todo momento a lo aquí establecido. Al acceder o utilizar esta
        plataforma web,
        <b
          >acepto expresa, libre y voluntariamente los siguientes Términos y
          Condiciones de Uso.</b
        >
      </p>
      <p style="font-size: 12px; text-align: justify">
        Reconozco y acepto que la plataforma PLANPRO en la persona de su
        administrador o titular, se reserva el derecho de negarme el acceso a la
        misma en cualquier caso que considere apropiado, en particular si yo
        como usuario:
      </p>

      <div style="font-size: 12px; text-align: justify">
        <ol>
          <li>Proporciono <b>datos falsos</b></li>
          <li>
            <b>Incumplo</b> estos Términos y Condiciones de Uso de cualquier
            forma; y,
          </li>
          <li>
            Incumplo <b>cualquier normativa legal</b> aplicable respecto del
            acceso o el uso de la presente plataforma.
          </li>
        </ol>
      </div>

      <p style="font-size: 12px; text-align: justify">
        Acepta libre, voluntaria y expresamente que soy el único responsable del
        contenido que cargue, publique, envíe por correo electrónico, transmita
        o de cualquier forma ponga a disposición a través de esta plataforma.
      </p>

      <p style="font-size: 12px; text-align: justify">
        Adicionalmente me obligo expresa, libre y voluntariamente a
        <b
          >no utilizar la presente plataforma en forma alguna que sirva directa
          o indirectamente para:</b
        >
      </p>

      <div style="font-size: 12px; text-align: justify">
        <ul>
          <li>Dañar a otras personas o animales de cualquier forma;</li>
          <li>Realizar declaraciones falsas;</li>
          <li>
            Difundir de cualquier forma contenido que viole un derecho de
            propiedad intelectual de terceros, incluyendo pero no limitado a
            marcas, derechos de autor, secretos empresariales, patentes y
            diseños industriales; y,
          </li>
          <li>
            Violar cualquier ley o norma jurídica nacional o internacional.
          </li>
        </ul>
      </div>

      <p style="font-size: 12px; text-align: justify">
        Reconozco y acepto que la plataforma PLANPRO en la persona de su
        administrador o titular se reserva el derecho de modificar a su
        discreción los presentes términos y condiciones, comunicándome el
        particular, siempre de acuerdo con la ley ecuatoriana.
      </p>

      <p style="font-size: 12px; text-align: justify">
        <b>Datos Personales:</b> Autorizo expresamente a PLANPROCAD S.A. a
        utilizar mis datos personales entregados o generados por medio de mi
        utilización de la plataforma PLANPRO. Esta autorización incluye los
        siguientes usos:
      </p>

      <div style="font-size: 12px; text-align: justify">
        <ul>
          <li>Acceso a datos;</li>
          <li>Consulta de buró crediticio;</li>
        </ul>
      </div>
    </div>
    <div class="modal__action">
      <div class="row">
        <div
          class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
          align="center"
        >
          <button
            type="button"
            class="btn btn-primary"
            id="btn-terms"
            @click="confirmar"
            style="width: 100%"
            :disabled="acceptingTerms"
          >
            Aceptar
          </button>
        </div>
      </div>
    </div>
  </VueFinalModal>
  <!-- 
  <h5 class="mt-3" style="text-align: center; margin-bottom: 15px">
    ¿Cómo deseas ingresar?
  </h5> -->

  <div class="loginButtonsContainer">
    <Skeletor
      v-if="loading"
      :shimmer="true"
      class="roundedBtn"
      height="40px"
      width="100%"
    />

    <Skeletor
      v-if="loading"
      :shimmer="true"
      class="roundedBtn"
      height="40px"
      width="100%"
    />

    <Skeletor
      v-if="loading"
      :shimmer="true"
      class="roundedBtn"
      height="40px"
      width="100%"
    />
    <button
      class="mt-1 roundedBtn btn-flex btn-google"
      @click="handleProviderClick(!isOnInAppBrowser ? 'google' : null)"
      variant="gradient"
      color="success"
      size="lg"
      v-if="!loading"
    >
      <img src="../assets/img/Xtrim/google.png" class="google-icon" />
      Iniciar Sesión con Google
    </button>
    <button
      class="mt-1 roundedBtn btn-flex btn-microsoft"
      @click="handleProviderClick(!isOnInAppBrowser ? 'microsoft' : null)"
      variant="gradient"
      color="success"
      size="lg"
      v-if="!loading"
    >
      <img src="../assets/img/Xtrim/microsoft.png" class="microsoft-icon" />
      Iniciar sesión con Microsoft
    </button>
    <button
      class="mt-1 roundedBtn btn-flex btn-yahoo"
      @click="handleProviderClick(!isOnInAppBrowser ? 'yahoo' : null)"
      variant="gradient"
      color="success"
      size="lg"
      v-if="!loading"
    >
      <i class="fa-brands fa-yahoo yahoo-icon" style="font-size: 22px"></i>
      Iniciar Sesión con Yahoo
    </button>
  </div>
</template>

<script>
/*eslint-disable */
import ArgonButton from "@/components/ArgonButton.vue";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/app-check";
import { VueFinalModal } from "vue-final-modal";
import "vue-final-modal/style.css";
import { inject } from "vue";
import { getToken } from "firebase/app-check";

export default {
  name: "formulario",
  components: {
    ArgonButton,
    VueFinalModal,
  },
  props: ["promoCode"],
  data() {
    return {
      showModal: false,
      showTerminos: false,
      userData: null,
      tokenAuth: null,
      installEvent: null,
      appCheck: null,
      isActiveGoogle: false,
      providerGoogle: new firebase.auth.GoogleAuthProvider(),
      providerMicrosoft: new firebase.auth.OAuthProvider("microsoft.com"),
      providerYahoo: new firebase.auth.OAuthProvider("yahoo.com"),
      loading: true,
      acceptingTerms: false,
      userLoginId: null,
      isOnInAppBrowser: false,
    };
  },
  watch: {},
  computed: {},
  async mounted() {
    this.checkInAppBrowser();
    this.loading = true; // Set loading state
    const appCheck = inject("appCheck"); // Inject the appCheck instance
    this.tokenAuth = this.$store.state.tokenAuth; // Get the token from the store

    const isValid = await this.confirmValidity(this.$store.state.tokenAuth);
    // Check if a new token is needed
    if (!isValid) {
      try {
        const response = await getToken(appCheck, true); // Fetch a new token
        this.tokenAuth = response.token; // Update the local state
        this.$store.commit("setTokenAuth", response.token); // Save the token in the store
      } catch (error) {
        console.error("Error fetching App Check token:", error); // Log errors
      }
    } else {
      const response = await getToken(appCheck, true); // Fetch a new token
      this.tokenAuth = response.token; // Update the local state
      this.$store.commit("setTokenAuth", response.token); // Save the token in the store
    }

    this.loading = false; // Stop loading state
  },
  methods: {
    async confirmValidity(tokenAuth) {
      if (!tokenAuth) {
        return false; // Token is missing, return false immediately
      }
      try {
        const response = await this.$https.post(
          "/access/verifyTokenValidity/",
          {
            tokenAuth,
          },
        );
        return response.data.isValid; // Return the validity status
      } catch (error) {
        console.error("Error during token validity check:", error);
        return false; // Assume the token is invalid in case of an error
      }
    },
    confirmar() {
      this.acceptingTerms = true;
      document.getElementById("btn-terms").innerHTML =
        '<i class="fas fa-spinner fa-pulse"></i>  Aceptando...';
      this.$https
        .post("/access/acceptTerms/", { userLoginId: this.userLoginId })
        .then((response) => {
          this.userData = response.data.user;
          this.$store.commit("setUser", this.userData);
          this.showTerminos = false;
          document.getElementById("btn-terms").innerHTML = "Aceptar";
          this.$router.push({ name: "crearPerfil" });
        })
        .catch((error) => {
          console.log(error);
          document.getElementById("btn-terms").innerHTML = "Aceptar";
          this.showTerminos = false;
          this.acceptingTerms = true;
          this.$toast.error(error, {
            position: "top-right",
            max: 10,
          });
        });
    },

    authentication(type) {
      // Ensure the provider always asks to select an account
      if (type === "google") {
        this.providerGoogle.setCustomParameters({ prompt: "select_account" });
      } else if (type === "microsoft") {
        this.providerMicrosoft.setCustomParameters({
          prompt: "select_account",
        });
      } else if (type === "yahoo") {
        this.providerYahoo.setCustomParameters({ prompt: "select_account" });
      }

      firebase
        .auth()
        .signInWithPopup(
          type === "google"
            ? this.providerGoogle
            : type === "microsoft"
              ? this.providerMicrosoft
              : this.providerYahoo,
        )
        .then((result) => {
          const { user } = result;
          const userId_json = {
            email: user.email,
            displayName: user.displayName,
            photoURL: user.photoURL,
            userLoginId: user.uid,
            tokenAuth: this.tokenAuth,
            promoCode: this.promoCode,
          };

          this.$store.commit("setUser", userId_json);

          this.$swal({
            html: '<div class="row"><div class="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2"></div><div class="col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1"><div class="loader"> </div></div><div class="col-7 col-sm-7 col-md-7 col-lg-7 col-xl-7"><p style="margin:0px; font-size:16px; text-align:center; font-weight:bold">Iniciando sesión</p></div><div class="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2"></div></div>',
            showCloseButton: false,
            showCancelButton: false,
            focusConfirm: false,
            allowEscapeKey: false,
            allowOutsideClick: false,
            showConfirmButton: false,
            position: "top",
          });
          this.$https
            .post("/access/login/", {
              tokenAuth: this.tokenAuth,
              ...userId_json,
            })
            .then((response) => {
              this.$swal.close();
              if (response.data.code == 200) {
                this.userData = response.data.user;
                this.$store.commit("setUser", this.userData);
                const isNewUser = response.data.user.isNewUser;
                const isVerified = response.data.user.isVerified;
                this.userLoginId = response.data.user.userLoginId;
                if (!isVerified && !isNewUser) {
                  this.$router.push({ name: "crearPerfil" });
                } else if (isNewUser) {
                  this.showTerminos = true;
                } else {
                  this.$router.push({ name: "Dashboard" });
                }
              } else {
                this.$toast.error(response.data.message, {
                  position: "top-right",
                  max: 10,
                });

                this.isActiveGoogle = false;
              }
            })
            .catch((error) => {
              this.$toast.error("2) Ocurrió un error inesperado: " + error, {
                position: "top-right",
                max: 10,
              });
            });
        })
        .catch((error) => {
          // Handle Errors here.

          const errorCode = error.code;

          this.isActiveGoogle = false;

          if (errorCode == "auth/popup-closed-by-user") {
          }

          if (errorCode == "auth/user-disabled") {
            this.$toast.error(
              "Lo sentimos, su cuenta se encuentra inhabilitado actualmente",
              {
                position: "top-right",
                max: 10,
              },
            );
          }

          if (errorCode == "auth/account-exists-with-different-credential") {
            this.$toast.error(
              "Este correo está siendo utilizado por una cuenta registrada con otro proveedor",
              {
                position: "top-right",
                max: 10,
              },
            );
          }

          // ...
        });
    },
    checkInAppBrowser() {
      const ua = navigator.userAgent.toLowerCase();
      const inApp = /instagram|fbav|fban|snapchat|tiktok/i.test(ua);
      this.isOnInAppBrowser = inApp;
    },
    openInDefaultBrowser() {
      const url = window.location.origin + window.location.pathname; // Keeps the path
      const params = window.location.search; // Keeps query params (if any)
      const fullUrl = url + params; // Reconstruct full URL

      if (/iPhone|iPad|iPod/.test(navigator.userAgent)) {
        // iOS Safari Redirect Trick
        window.location.href = `x-safari-${fullUrl}`;
      } else if (/android/i.test(navigator.userAgent)) {
        // Android Chrome Intent
        window.location.href = `intent://${fullUrl.replace(/^https?:\/\//, "")}#Intent;scheme=https;package=com.android.chrome;end;`;
      } else {
        // Default behavior (open in new tab)
        const newWindow = window.open(fullUrl, "_blank");
        if (!newWindow) {
          window.location.href = fullUrl; // Fallback
        }
      }
    },
    handleProviderClick(provider) {
      if (provider) {
        this.authentication(provider);
      } else {
        this.openInDefaultBrowser();
      }
    },
  },
  created() {},
  beforeUnmount() {},
};
</script>

<style scoped>
.bg-gradient-success {
  background-image: linear-gradient(310deg, #591361 0%, #7d0c7e 100%);
}

.m-input-wrapper-input.--md .m-input-input,
.m-input-wrapper-input.--md .m-input-label {
  font-size: 12px !important;
}

.m-input.--md .m-input-input,
.m-input.--md .m-input-label {
  font-size: 12px !important;
  line-height: 1.5rem;
}

.btn:disabled,
.btn.disabled,
fieldset:disabled .btn {
  cursor: not-allowed;
  pointer-events: auto;
}

.loginButtonsContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;
  margin-top: 20px;
  padding: 0 10px;
  width: 100%;
  max-width: 320px;
}

.roundedBtn {
  border-radius: 8px;
}
</style>
