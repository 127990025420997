<template>
  <div class="container-fluid">
    <div
      class="menuContainer"
      style="min-height: calc(var(--vh, 1vh) * 100); margin: 0px"
    >
      <div class="responsiveContainer">
        <img
          src="../../src/assets/img/Xtrim/Xtrim-Pro-logo.png"
          style="max-width: 300px; width: 75%; padding: 0 0 10px 0"
        />

        <div v-if="!registerCompleted" class="row align-items-center loginCard">
          <button @click="exit" class="exitBtn">
            <i
              class="fas fa-sign-out-alt text-success text-sm opacity-10"
              style="font-size: 6px"
            ></i>
            <span style="color: #7d0c7e; font-size: 12px; font-weight: bold">
              Cerrar sesión</span
            >
          </button>
          <div style="width: 100%; padding: 0">
            <img
              v-if="!showData"
              src="../../src/assets/img/Xtrim/pro-porque-puedo-con-xtrim.png"
              style="max-width: 300px; width: 75%; padding: 0 0 10px 0"
            />
            <p
              v-if="showData"
              style="
                padding: 5px 10px;
                background: rgb(170, 50, 189);
                background: linear-gradient(
                  68deg,
                  rgba(170, 50, 189, 1) 0%,
                  rgba(217, 76, 225, 1) 100%
                );
                border: 1px solid #ec62ea;
                font-size: 14px;
                color: white;
                font-family: Meloriac;
                margin: 12px 0;
                letter-spacing: 1.5px;
                width: auto;
                border-radius: 20px;
              "
            >
              ESTÁS A POCO DE RECIBIR TUS PRIMEROS
              <span style="color: #f9cc03">$20</span>
              ¡PONTE PILAS!
            </p>

            <h5
              class="mb-0 text-center"
              style="
                font-size: 16px;
                padding: 10px 0 16px 0;
                color: #7d0c7e;
                font-weight: 900;
              "
            >
              ¡Completa tu perfil y empieza a ganar!
            </h5>

            <div class="dataContainer">
              <form
                role="form"
                autocomplete="off"
                @submit.prevent="onSubmitSearch"
                class="dniInputContainer"
              >
                <div style="margin-bottom: 10px">
                  <label
                    for="example-text-input"
                    class="form-control-purple-label"
                    style="
                      font-weight: bold;
                      font-size: 12px;
                      margin-bottom: 4px;
                      text-align: start;
                      width: 100%;
                      color: #7d0c7e;
                    "
                    >Ingresa tu número de cédula (*):</label
                  >
                  <input
                    type="text"
                    required
                    :readonly="isDisabled"
                    class="form-control-purple"
                    ref="myDni"
                    placeholder="Número de cédula"
                    v-model="user.dni"
                    aria-label="Número de cédula"
                    aria-describedby="button-addon2"
                    @input="validateNumbers"
                  />
                </div>
                <button
                  class="btn btn-yellow btn-flex"
                  :disabled="isDisabled"
                  type="submit"
                  v-if="!retrievedData"
                >
                  ¡QUIERO SER UN PRO!
                  <!-- <i
                    class="fa fa-search"
                    aria-hidden="true"
                    style="widows: 100%"
                  ></i> -->
                </button>
              </form>
            </div>
            <form
              @submit.prevent="verifyProfile"
              v-if="showData"
              class="dataContainer"
            >
              <div class="inputFieldLabel">
                <label
                  for="example-text-input"
                  class="form-control-purple-label"
                  style="
                    align-self: flex-start;
                    margin-bottom: 4px;
                    color: #7d0c7e;
                  "
                  >Nombres</label
                >
                <input
                  class="form-control-purple text-uppercase"
                  type="text"
                  autocomplete="off"
                  disabled
                  v-model="user.names"
                />
              </div>
              <div class="inputFieldLabel">
                <label
                  for="lastnames-input"
                  class="form-control-purple-label"
                  style="
                    align-self: flex-start;
                    margin-bottom: 4px;
                    color: #7d0c7e;
                  "
                >
                  Apellidos
                </label>
                <input
                  id="lastnames-input text-uppercase"
                  class="form-control-purple"
                  type="text"
                  autocomplete="off"
                  disabled
                  v-model="user.lastnames"
                />
              </div>

              <div class="inputFieldLabel">
                <label
                  for="example-text-input"
                  class="form-control-purple-label"
                  style="
                    align-self: flex-start;
                    margin-bottom: 4px;
                    color: #7d0c7e;
                  "
                  >Fecha de nacimiento</label
                >
                <input
                  class="form-control-purple"
                  type="date"
                  disabled
                  v-model="user.birthDate"
                />
              </div>
              <div class="inputFieldLabel">
                <label
                  for="example-text-input"
                  class="form-control-purple-label"
                  style="
                    align-self: flex-start;
                    margin-bottom: 4px;
                    color: #7d0c7e;
                  "
                  >Número celular (*):</label
                >
                <MazPhoneNumberInput
                  type="tel"
                  default-country-code="EC"
                  style="width: 100%"
                  no-flags
                  no-example
                  required
                  show-code-on-list
                  size="md"
                  v-model="user.phone"
                  @update="results = $event"
                  :translations="{
                    countrySelector: {
                      placeholder: 'Código',
                      error: 'Elija un pais',
                    },
                    phoneInput: {
                      placeholder: 'Nro. celular',
                      example: 'Ejemplo:',
                    },
                  }"
                />
              </div>
              <div class="buttonsContainer">
                <hr class="horizontal dark" style="margin: 3px 0" />
                <button
                  id=""
                  type="button"
                  class="btn btn-primary"
                  style="width: 100%"
                  v-on:click="reset()"
                >
                  Reiniciar búsqueda
                </button>
                <button
                  id="btn_update"
                  type="submit"
                  :disabled="isUpdating"
                  class="btn btn-yellow-normal"
                  style="width: 100%"
                >
                  Actualizar perfil
                </button>
              </div>
            </form>
          </div>
        </div>
        <div
          v-else
          class="loginCard"
          style="
            position: relative;
            border: 6px solid #da31da;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
          "
        >
          <img src="../../src/assets/img/Xtrim/latia.png" style="width: 75%" />
          <div class="middleBoxButton">
            <button
              type="button"
              @click="goToDashboard"
              class="btn btn-yellow-normal"
              style="
                font-weight: 900;
                letter-spacing: 1.5px;
                font-size: 18px;
                border-radius: 18px;
                width: max-content;
              "
            >
              ¡QUIERO GANAR YA!
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/*eslint-disable */

import myMixin from "../mixin.js";

export default {
  name: "crearPerfil",
  data() {
    const { logout } = myMixin();
    const datos_json = this.$store.state.user;
    return {
      logout: logout,
      showData: false,
      isDisabled: false,
      isUpdating: false,
      userId: datos_json.userId,
      userLoginId: datos_json.userLoginId,
      tokenAuth: this.$store.state.tokenAuth,
      retrievedData: false,
      results: {},
      registerCompleted: false,
      user: {
        fullName: "",
        names: "",
        namesList: [],
        lastnames: "",
        lastnamesList: [],
        dni: "",
        birthDate: "",
        gender: "",
        maritalStatus: "",
        phone: "",
      },
    };
  },
  methods: {
    exit() {
      this.logout(this.userLoginId, this.tokenAuth, true);
    },

    goToDashboard() {
      this.$router.push({ name: "Dashboard" });
    },

    reset() {
      this.retrievedData = false;
      this.user = {
        names: "",
        namesList: [],
        lastnames: "",
        lastnamesList: [],
        dni: "",
        birthDate: "",
        gender: "",
        maritalStatus: "",
      };
      this.showData = false;
      this.isDisabled = false;
    },

    validateNumbers(event) {
      // Allow only numeric input
      const value = event.target.value.replace(/\D/g, ""); // Remove non-digit characters
      this.user.dni = value;
    },

    onSubmitSearch(event) {
      event.preventDefault();

      this.getDatos();
    },
    getDatos() {
      if (this.user.dni.length === 10) {
        this.consultar();
      } else {
        this.$swal.fire({
          title: "Cédula incorrecta!",
          icon: "warning",
          width: "400px",
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-outline-secondary ml-1",
          },
          buttonsStyling: false,
        });

        this.user.dni = "";
      }
    },
    consultar() {
      this.$swal({
        html: '<div class="row"><div class="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2"></div><div class="col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1"><div class="loader"> </div></div><div class="col-7 col-sm-7 col-md-7 col-lg-7 col-xl-7"><p style="margin:0px; font-size:16px; text-align:center; font-weight:bold">Consultando, espere</p></div><div class="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2"></div></div>',
        showCloseButton: false,
        showCancelButton: false,
        focusConfirm: false,
        allowEscapeKey: false,
        allowOutsideClick: false,
        showConfirmButton: false,
        position: "top",
      });

      this.$https
        .post("/profile/checkUserExist/", {
          tokenAuth: this.tokenAuth,
          dni: this.user.dni,
        })
        .then((response) => {
          this.$swal.close();

          this.showData = true;
          this.user.fullName = response.data.data.nombres.trim();
          this.user.birthDate = response.data.data.nacimiento;
          this.user.maritalStatus = response.data.data.estadocivil;
          let gender = response.data.data.sexo;
          const nameParts = this.splitSpanishName(this.user.fullName); // Split by spaces
          this.user.lastnames = nameParts.lastnames; // First two parts are last names
          this.user.names = nameParts.names; // Remaining parts are first names
          this.user.namesList = nameParts.namesList;
          this.user.lastnamesList = nameParts.lastnamesList;

          gender === "1"
            ? (this.user.gender = "Masculino")
            : (this.user.gender = "Femenino");

          this.isDisabled = true;
          this.retrievedData = true;
        })
        .catch((error) => {
          if (error.status === 401) {
            this.$toast.error("Sesión expirada. Inicie sesión nuevamente", {
              position: "top-right",
              max: 10,
            });
            this.$router.push({ name: "Signin" });
            this.$store.dispatch("clearAllData");
            this.$swal.close();
          } else {
            this.isDisabled = false;
            this.retrievedData = false;
            this.$swal.close();
            this.$toast.error(error.response.data.message, {
              position: "top-right",
              max: 10,
            });
          }
        });
      // El número de teléfono ya está registrado. Intente con otro.
    },

    splitSpanishName(fullName) {
      const particles = ["DE", "LA", "DEL", "LAS", "LOS", "SAN", "SANTA"]; // List of particles in uppercase
      const nameParts = fullName.trim().split(/\s+/); // Split the name into individual words
      let lastnames = []; // This will hold the lastnames
      let names = []; // This will hold the names
      let tempList = []; // Temporary list to hold current group of words
      let i = 0;
      let lastnamesNumber = 0;

      // Handle first part for lastnames (grouping particles and first last name)
      while (lastnamesNumber < 2) {
        while (i < nameParts.length) {
          const currentWord = nameParts[i].toUpperCase();
          if (particles.includes(currentWord)) {
            // If it's a particle, add to tempList
            tempList.push(currentWord);
            i++;
          } else {
            // Stop when a non-particle word is encountered
            tempList.push(nameParts[i]);
            lastnames.push(tempList.join(" ")); // Save lastnames
            tempList = []; // Reset tempList for next part
            i++; // Move to the next word
            break; // Stop the loop for lastnames part
          }
        }
        lastnamesNumber++;
      }

      // Handle second part for names (grouping the remaining names)
      while (i < nameParts.length) {
        const currentWord = nameParts[i];

        if (particles.includes(currentWord)) {
          // If it's a particle, add to tempList
          tempList.push(currentWord);
          i++;
        } else {
          // Otherwise, add word to names
          tempList.push(currentWord);
          names.push(tempList.join(" ")); // Save names
          tempList = []; // Reset tempList for next part
          i++;
        }
      }
      // Return the split result: first part is lastnames, second part is names
      return {
        lastnames: lastnames.join(" "), // Join all parts for lastnames
        names: names.join(" "), // Join all parts for names
        lastnamesList: lastnames,
        namesList: names,
      };
    },

    verifyProfile() {
      if (!this.results.isValid || this.results.phoneNumber === "") {
        // If not valid, show a message or take some action
        this.$toast.error("Ingresa un número celular válido", {
          position: "top-right",
          max: 10,
        });
        return;
      }
      const user_json = { userLoginId: this.userLoginId, user: this.user };

      document.getElementById("btn_update").innerHTML =
        '<i class="fas fa-spinner fa-pulse"></i>  Actualizando...';
      this.isUpdating = true;
      this.$https
        .post("/profile/updateProfile/", {
          tokenAuth: this.tokenAuth,
          ...user_json,
        })
        .then((response) => {
          document.getElementById("btn_update").innerHTML = "Actualizar perfil";

          this.isUpdating = false;

          this.$toast.success("Cédula registrada exitosamente!", {
            position: "top-right",
            max: 10,
          });
          this.$store.commit("setUser", response.data.updatedProfile);
          this.registerCompleted = true;
          // this.$router.push({ name: "Dashboard" });
        })
        .catch((error) => {
          document.getElementById("btn_update").innerHTML = "Actualizar perfil";
          this.isUpdating = false;
          if (error.response.data.code == 401) {
            this.$store.dispatch("clearAllData");
            this.$toast.error("Sesión expirada. Inicie sesión nuevamente", {
              position: "top-right",
              max: 10,
            });
            this.$router.push({ name: "login" });
          } else if (error.response.data.code === 402) {
            this.user.dni = "";
            this.isDisabled = false;
            this.isUpdating = false;
            this.showData = false;
            this.$refs.myDni.focus();
          } else {
            this.$toast.error(error.response.data.message, {
              position: "top-right",
              max: 10,
            });
          }
        });
    },
  },
  mounted() {
    this.$refs.myDni.focus();
  },
  created() {},
  beforeUnmount() {},
  watch: {},
};
</script>

<style scoped>
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.container-fluid {
  padding: 0px;
  margin: 0px;
}

.bg-dark {
  background: linear-gradient(87deg, #591361, #7d0c7e) !important;
  height: 160px;
}

.loginCard {
  padding: 20px 14px 30px 14px;
  border-radius: 15px;
  background: #fbfbfb;
  box-shadow: 0px 0px 15px 15px #53045465;
  max-width: 400px;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  @media (min-width: 768px) {
    padding: 20px 30px 40px 30px;
  }
}

.container {
  width: 91.666667%; /* Equivalent to col-11 */
  @media (min-width: 576px) {
    width: 100%; /* Equivalent to col-sm-12 */
  }
  @media (min-width: 768px) {
    width: 100%; /* Equivalent to col-md-12 */
  }
  @media (min-width: 992px) {
    width: 100%; /* Equivalent to col-lg-12 */
  }
  @media (min-width: 1200px) {
    width: 66.66666666%; /* Equivalent to col-xl-4 */
    display: flex;
    justify-content: center; /* Centers content horizontally */
    text-align: center; /* Centers text */
    align-items: center; /* Centers content vertically */
  }
  display: none;
}

.responsiveContainer {
  width: 100%; /* Equivalent to col-11 */
  margin: 0 15px;
  /* Responsive widths */
  @media (min-width: 576px) {
    width: 100%; /* Equivalent to col-sm-12 */
  }
  @media (min-width: 768px) {
    width: 100%; /* Equivalent to col-md-12 */
  }
  @media (min-width: 992px) {
    width: 100%; /* Equivalent to col-lg-12 */
  }
  @media (min-width: 1200px) {
    width: 100%; /* Equivalent to col-xl-4 */
  }

  /* Flexbox properties */
  display: flex;
  justify-content: center; /* Centers content horizontally */
  text-align: center; /* Centers text */
  align-items: center; /* Centers content vertically */
  flex-direction: column;
}

.menuContainer {
  display: flex;
  min-height: 100vh;
  height: 100%;
  justify-content: center;
  align-items: center;
  min-height: calc(var(--vh, 1vh) * 100);
  margin: 0px;
  padding: 0;
  background-color: #7d0c7e !important;
  background: url("../../src/assets/img/Xtrim/register-background.png")
    no-repeat center center;
  background-size: cover;
  /* @media (max-width: 1199px) {
  } */
}

.dniInputContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
}

.dataContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  width: 100%;
}

.inputFieldLabel {
  margin-bottom: 2px;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.buttonsContainer {
  display: flex;
  gap: 8px;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
}

.exitBtn {
  background-color: #e9e9e9;
  padding: 7px 20px;
  border: none;
  border-radius: 50px;
  width: fit-content;
  align-self: flex-end;
}
.exitBtn:hover {
  background-color: #e2e2e2;
  transform: translateY(-1px);
}

.middleBoxButton {
  position: absolute;
  bottom: -25px;
  left: 50%;
  transform: translateX(-50%);
  transform-origin: 5% 50%; /* Move the origin to the left edge */
}
</style>
