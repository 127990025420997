<template>
  <div class="card" style="margin-bottom: 10px">
    <div class="card-body">
      <div class="row">
        <div class="col-10 col-sm-10 col-md-11 col-lg-11 col-xl-11">
          <p style="margin: 10px; font-weight: bold">
            <i style="margin-right: 10px" class="fas fa-list"></i> Solicitudes
            de retiros de dinero
          </p>
        </div>

        <div
          class="col-2 col-sm-2 col-md-1 col-lg-1 col-xl-1"
          align="center"
          style="display: flex"
        >
          <Skeletor
            v-if="loading"
            :shimmer="true"
            height="20"
            width="100%"
            style="margin: auto"
          />
          <div v-else @click="refresh">
            <i
              style="cursor: pointer; margin: auto"
              class="fas fa-sync text-sm opacity-10"
            ></i>
          </div>
        </div>
      </div>

      <div
        v-if="loading"
        class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
        style="padding: 0px"
      >
        <div class="row">
          <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
            <Skeletor :shimmer="true" height="40" width="50%" />
          </div>

          <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
            <Skeletor
              :shimmer="true"
              height="10"
              pill
              width="30%"
              style="margin-bottom: 10px"
            />
            <Skeletor :shimmer="true" height="40" width="100%" />
          </div>
          <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <table class="table tablesorter">
              <thead class="thead-light">
                <tr>
                  <th><Skeletor :shimmer="true" height="10" width="100%" /></th>
                  <th><Skeletor :shimmer="true" height="10" width="100%" /></th>
                  <th><Skeletor :shimmer="true" height="10" width="100%" /></th>
                  <th><Skeletor :shimmer="true" height="10" width="100%" /></th>
                  <th><Skeletor :shimmer="true" height="10" width="100%" /></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td><Skeletor :shimmer="true" height="20" width="100%" /></td>
                  <td><Skeletor :shimmer="true" height="20" width="100%" /></td>
                  <td><Skeletor :shimmer="true" height="20" width="100%" /></td>
                  <td><Skeletor :shimmer="true" height="20" width="100%" /></td>
                  <td><Skeletor :shimmer="true" height="20" width="100%" /></td>
                </tr>
                <tr>
                  <td><Skeletor :shimmer="true" height="20" width="100%" /></td>
                  <td><Skeletor :shimmer="true" height="20" width="100%" /></td>
                  <td><Skeletor :shimmer="true" height="20" width="100%" /></td>
                  <td><Skeletor :shimmer="true" height="20" width="100%" /></td>
                  <td><Skeletor :shimmer="true" height="20" width="100%" /></td>
                </tr>
                <tr>
                  <td><Skeletor :shimmer="true" height="20" width="100%" /></td>
                  <td><Skeletor :shimmer="true" height="20" width="100%" /></td>
                  <td><Skeletor :shimmer="true" height="20" width="100%" /></td>
                  <td><Skeletor :shimmer="true" height="20" width="100%" /></td>
                  <td><Skeletor :shimmer="true" height="20" width="100%" /></td>
                </tr>
              </tbody>
            </table>
          </div>

          <div class="col-6 col-sm-6 col-md-8 col-lg-8 col-xl-8">
            <Skeletor :shimmer="true" height="10" pill width="80%" />
          </div>

          <div class="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-4">
            <div class="row">
              <div class="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                <Skeletor :shimmer="true" height="10" width="100%" />
              </div>

              <div
                class="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4"
                align="center"
              >
                <Skeletor :shimmer="true" circle size="30" />
              </div>

              <div class="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                <Skeletor :shimmer="true" height="10" width="100%" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-else-if="notAllow" class="row">
        <div
          class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
          align="center"
        >
          <img style="width: 100px" src="/img/caution.svg" />

          <p style="margin-top: 10px; font-size: 14px">
            Tu rol no te permite utilizar la siguiente funcionalidad.
          </p>
        </div>
      </div>

      <DataTable
        v-else
        :columns="columns"
        :options="options"
        :data="transactions"
        class="table tablesorter"
        width="100%"
      >
        <thead>
          <tr>
            <th>Fecha/Hora</th>
            <th data-priority="1">Usuario</th>
            <th>DNI</th>
            <th>Teléfono</th>
            <th>Email</th>
            <th>Monto</th>
            <th data-priority="2">Acción</th>
          </tr>
        </thead>
      </DataTable>
    </div>
  </div>

  <VueFinalModal
    v-model="showModal"
    :esc-to-close="true"
    class="modal-container"
    content-class="modal-content"
  >
    <div class="modal__content">
      <div class="row">
        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <p style="text-align: center; font-weight: bold; margin: 0px">
            Datos del usuario
          </p>

          <p class="p_text">
            <span class="textLabel">Usuario: </span>{{ displayName }}
          </p>

          <p class="p_text"><span class="textLabel">DNI: </span>{{ dni }}</p>

          <p class="p_text">
            <span class="textLabel">Contacto: </span>{{ contact }}
          </p>
        </div>

        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <hr class="horizontal dark" />
          <p style="text-align: center; font-weight: bold; margin: 0px">
            Datos bancarios
          </p>

          <p class="p_text"><span class="textLabel">Banco: </span>{{ bank }}</p>

          <p class="p_text">
            <span class="textLabel">Nro. cuenta: </span>{{ accountNumber }}
          </p>

          <p class="p_text"><span class="textLabel">Tipo: </span>{{ type }}</p>
        </div>

        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <hr class="horizontal dark" />
          <p style="text-align: center; font-weight: bold; margin: 0px">
            Datos de transacción
          </p>

          <p class="p_text"><span class="textLabel">Fecha: </span>{{ date }}</p>

          <p class="p_text">
            <span class="textLabel">Referencia: </span>{{ ref }}
          </p>

          <p class="p_text">
            <span class="textLabel">Monto a transferir: </span>$ {{ amount }}
          </p>
        </div>
      </div>
    </div>
  </VueFinalModal>
</template>

<script>
/* eslint-disable */

import DataTable from "datatables.net-vue3";
import DataTablesCore from "datatables.net-bs5";
import "datatables.net-responsive";
import $ from "jquery";
import { VueFinalModal } from "vue-final-modal";
import "vue-final-modal/style.css";

DataTable.use(DataTablesCore);

export default {
  components: {
    DataTable,
    VueFinalModal,
  },
  props: ["userId", "tokenAuth", "role"],
  data() {
    return {
      loading: true,
      showModal: false,
      transactions: [],
      notAllow: false,
      displayName: "",
      dni: "",
      contact: "",
      bank: "",
      accountNumber: "",
      type: "",
      date: "",
      ref: "",
      amount: 0,
      columns: [
        { data: "dateFormatted" },
        { data: "referrerFullName" },
        { data: "dni" },
        { data: "phone" },
        { data: "email" },
        {
          data: null,
          render: function (data) {
            return `$${data.amount.toFixed(2)}`;
          },
        },
        {
          data: null,
          render: function (data) {
            const bankUserName =
              data.bank.bankUserName ?? data.referrerFullName;
            const bankUserDni = data.bank.bankUserDni ?? data.dni;
            const bankUserEmail = data.bank.bankUserEmail ?? data.email;
            return `
            <div class="dropdown">
  <button class="btn btn-primary btn-sm dropdown-toggle " type="button" id="dropdownMenu2" data-bs-toggle="dropdown" aria-expanded="false">
    <i class="fas fa-ellipsis-v" style="margin-right:10px"></i>
  </button>
  <ul class="dropdown-menu" aria-labelledby="dropdownMenu2" style="background-color: white !important;">
    <li><button data-transactionid="${data.transactionId}" data-displayname="${
      data.referrerFullName
    }" data-bank="${data.bank.bank}" data-userid="${
      data.userId
    }"  data-amount="${data.amount.toFixed(
      2,
    )}" class="approve dropdown-item" type="button"><i class="fas fa-check-circle" style="margin-right:10px"></i>Aprobar retiro</button></li>
    <li><button data-transactionid="${data.transactionId}" data-displayname="${
      data.referrerFullName
    }" data-bank="${data.bank.bank}" data-userid="${
      data.userId
    }"  data-amount="${data.amount.toFixed(
      2,
    )}" class="dropdown-item reject" type="button"><i class="fas fa-times-circle" style="margin-right:10px"></i>Rechazar retiro</button></li>
    <li><button  data-amount="${data.amount.toFixed(2)}" data-date="${
      data.dateFormatted
    }"  data-ref="${data.ref}" data-type="${
      data.bank.type
    }" data-displayname="${bankUserName}" data-bank="${
      data.bank.bank
    }" data-accountnumber="${
      data.bank.accountNumber
    }" data-contact="${bankUserEmail}" data-dni="${bankUserDni}" class="dropdown-item verDatos" type="button"><i class="fas fa-eye" style="margin-right:10px"></i>Ver datos</button></li>
  </ul>
</div>`;
          },
        },
      ],
      options: {
        responsive: true,
        ordering: false,
        columnDefs: [
          { responsivePriority: 1, targets: 0 },
          { responsivePriority: 2, targets: -1 },
        ],
        lengthMenu: [
          [7, 15, 25, 35, 50, -1],
          [7, 15, 25, 35, 50, "All"],
        ],
        pageLength: 7,
        language: {
          search: "Buscar:",
          sInfo:
            "Mostrando registros del _START_ al _END_ de un total de _TOTAL_ registros",
          sInfoEmpty:
            "Mostrando registros del 0 al 0 de un total de 0 registros",
          searchPlaceholder: "Buscar por término...",
          zeroRecords: "Lo sentimos, no se encontraron resultados",
          processing: "Buscando. Espere por favor...",
          sLengthMenu: "Mostrar _MENU_ registros",
          oPaginate: {
            sFirst: "Primero",
            sLast: "Último",
            sNext: "Siguiente",
            sPrevious: "Anterior",
          },
        },
      },
    };
  },
  computed: {},
  watch: {},
  mounted() {
    $(document).off("click", ".approve");
    $(document).off("click", ".reject");

    $(document).on("click", ".approve", (evt) => {
      const displayName = $(evt.target).data("displayname");
      const bank = $(evt.target).data("bank");
      const transactionId = $(evt.target).data("transactionid");
      const userId = $(evt.target).data("userid");
      const amount = $(evt.target).data("amount");

      this.approveWithdrawal(transactionId, userId, displayName, bank, amount);
    });

    $(document).on("click", ".reject", (evt) => {
      const displayName = $(evt.target).data("displayname");
      const bank = $(evt.target).data("bank");
      const transactionId = $(evt.target).data("transactionid");
      const userId = $(evt.target).data("userid");
      const amount = $(evt.target).data("amount");

      this.rejectWithdrawal(transactionId, userId, displayName, bank, amount);
    });

    $(document).off("click", ".verDatos");

    $(document).on("click", ".verDatos", (evt) => {
      const displayName = $(evt.target).data("displayname");
      const dni = $(evt.target).data("dni");
      const contact = $(evt.target).data("contact");
      const bank = $(evt.target).data("bank");
      const accountNumber = $(evt.target).data("accountnumber");
      const type = $(evt.target).data("type");
      const date = $(evt.target).data("date");
      const ref = $(evt.target).data("ref");
      const amount = $(evt.target).data("amount");
      this.showDatos(
        displayName,
        dni,
        contact,
        bank,
        accountNumber,
        type,
        date,
        ref,
        amount,
      );
    });

    this.getListado();
  },
  methods: {
    rejectWithdrawal(transactionId, userId, displayName, bank, amount) {
      let title = "";
      let textButton = "";
      let html = "";
      html =
        '<div style="margin-top:10px" class="table-responsive"><table class="table table-bordered" style="text-align: center;"><tbody><tr><td class="background_table">Usuario:</td><td class="td_table">' +
        displayName +
        '</td></tr><tr><td class="background_table">Banco:</td><td class="td_table">' +
        bank +
        '</td></tr><tr> <td class="background_table">Monto:</td><td class="td_table">$' +
        amount +
        ' USD</td></tr></tbody></table><p style="text-align: left;margin-bottom: 0px;margin-top: 15px;">Ingrese el motivo del rechazo de la solicitud:</p>';

      title = "¿Estás seguro que deseas rechazar esta solicitud?";
      textButton = "Rechazar";

      this.$swal({
        title: title,
        html: html,
        icon: "warning",
        showCancelButton: true,
        input: "text",
        inputPlaceholder: "Motivo del rechazo de solicitud",
        inputAttributes: {
          autocapitalize: "off",
        },
        confirmButtonText: textButton,
        cancelButtonText: "Cancelar",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-secondary ml-1",
        },
        buttonsStyling: false,
        showLoaderOnConfirm: true,
        preConfirm: (description) => {
          if (description != "") {
            return this.$https
              .post("/administration/rejectWithdrawal/", {
                tokenAuth: this.tokenAuth,
                userId: userId,
                description: description,
                userIdAdmin: this.userId,
                role: this.role,
                transactionId: transactionId,
              })
              .then((response) => response.data)
              .catch((error) => {
                if (error.status === 401) {
                  this.$store.dispatch("clearAllData");
                  this.$toast.error(
                    "Sesión expirada. Inicie sesión nuevamente",
                    {
                      position: "top-right",
                      max: 10,
                    },
                  );
                  this.$router.push({ name: "Signin" });
                } else {
                  this.$swal.showValidationMessage(error);
                }
              });
          } else {
            this.$swal.showValidationMessage(
              "El motivo del rechazo es obligatorio",
            );
          }
        },
        allowOutsideClick: () => !this.$swal.isLoading(),
      })
        .then((result) => {
          this.$swal.close();
          if (result.isConfirmed) {
            if (result.value.code == 200) {
              this.refresh();
              this.$toast.success(result.value.message, {
                position: "top-right",
                max: 10,
              });
            }
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    },

    approveWithdrawal(transactionId, userId, displayName, bank, amount) {
      let title = "";
      let textButton = "";
      let html = "";
      html =
        '<div style="margin-top:10px" class="table-responsive"><table class="table table-bordered" style="text-align: center;"><tbody><tr><td class="background_table">Usuario:</td><td class="td_table">' +
        displayName +
        '</td></tr><tr><td class="background_table">Banco:</td><td class="td_table">' +
        bank +
        '</td></tr><tr> <td class="background_table">Monto:</td><td class="td_table">$' +
        amount +
        " USD</td></tr></tbody></table>";

      title = "¿Estás seguro que deseas aprobar esta solicitud?";
      textButton = "Aprobar";

      this.$swal({
        title: title,
        html: html,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: textButton,
        cancelButtonText: "Cancelar",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-secondary ml-1",
        },
        buttonsStyling: false,
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return this.$https
            .post("/administration/approveWithdrawal/", {
              tokenAuth: this.tokenAuth,
              userId: userId,
              userIdAdmin: this.userId,
              role: this.role,
              transactionId: transactionId,
            })
            .then((response) => response.data)
            .catch((error) => {
              if (error.status === 401) {
                this.$store.dispatch("clearAllData");
                this.$toast.error("Sesión expirada. Inicie sesión nuevamente", {
                  position: "top-right",
                  max: 10,
                });
                this.$router.push({ name: "Signin" });
              } else {
                this.$swal.showValidationMessage(error);
              }
            });
        },
        allowOutsideClick: () => !this.$swal.isLoading(),
      })
        .then((result) => {
          this.$swal.close();

          if (result.isConfirmed) {
            if (result.value.code == 200) {
              this.refresh();

              this.$toast.success(result.value.message, {
                position: "top-right",
                max: 10,
              });
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    showDatos(
      displayName,
      dni,
      contact,
      bank,
      accountNumber,
      type,
      date,
      ref,
      amount,
    ) {
      this.showModal = true;

      this.displayName = displayName;
      this.dni = dni;
      this.contact = contact;
      this.bank = bank;
      this.accountNumber = accountNumber;
      this.type = type;
      this.date = date;
      this.ref = ref;
      this.amount = amount;
    },

    refresh() {
      $(document).off("click", ".verDatos");
      $(document).off("click", ".approve");
      $(document).off("click", ".reject");

      this.$eventBus.emit("reiniciarListadoRetirosAdmin");
    },

    getListado() {
      this.$https
        .post("/administration/fetchWithdrawalTransactions/", {
          tokenAuth: this.tokenAuth,
          userId: this.userId,
          role: this.role,
        })
        .then((response) => {
          this.loading = false;
          this.notAllow = false;

          this.transactions = response.data.transactionsList;
        })
        .catch((error) => {
          if (error.response.data.code == 401) {
            // Redirect to login page
            this.$toast.error("Sesión expirada. Inicie sesión nuevamente", {
              position: "top-right",
              max: 10,
            });
            this.$store.dispatch("clearAllData");
            this.$router.push({ name: "login" });
          } else {
            if (error.response.data.code == 403) {
              this.$router.push({ name: "Dashboard" });
              this.$toast.error("Acceso no permitido", {
                position: "top-right",
                max: 10,
              });
            } else if (error.response.data.code == 404) {
              this.$toast.success(
                "No se encontraron transacciones de retiro pendientes",
                {
                  position: "top-right",
                  max: 10,
                },
              );
              this.loading = false;
              this.notAllow = false;
            } else {
              this.loading = false;
              this.notAllow = true;
              this.$toast.error(error, {
                position: "top-right",
                max: 10,
              });
            }
          }
        });
    },
  },
};
</script>

<style lang="scss">
@import "datatables.net-dt";
@import "datatables.net-responsive-dt";
</style>
