<template>
  <footer class="footerStyle">
    <!-- Social Media Links -->
    <div class="socialMedia">
      <a
        target="_blank"
        title="Facebook de XtrimPro"
        href="https://www.facebook.com/profile.php?id=100090370913056"
      >
        <i class="fab fa-facebook-f redes" aria-hidden="true"></i>
      </a>
      <a
        target="_blank"
        title="Instagram de XtrimPro"
        href="https://www.instagram.com/xtrimpro.ec/"
      >
        <i class="fab fa-instagram redes" aria-hidden="true"></i>
      </a>
      <a
        target="_blank"
        title="Email de XtrimPro"
        href="mailto:hola@xtrimproec.com?Subject=Tengo%20una%20pregunta"
      >
        <i class="fas fa-envelope redes" aria-hidden="true"></i>
      </a>
      <a
        target="_blank"
        title="Tiktok de XtrimPro"
        href="https://www.tiktok.com/@xtrimproec"
      >
        <i class="fab fa-tiktok redes" aria-hidden="true"></i>
      </a>
    </div>

    <!-- Terms and Links -->
    <p style="margin-top: 10px; margin-bottom: 0; font-size: 12px">
      <a
        href="https://firebasestorage.googleapis.com/v0/b/xtrimpro-refiereygana.firebasestorage.app/o/legal%2FTe%CC%81rminos-Y-Condiciones.pdf?alt=media&token=4794f901-fd8f-4496-a2a2-e08448d270a6"
        target="_blank"
        >Términos y condiciones</a
      >
      -
      <a
        href="https://firebasestorage.googleapis.com/v0/b/xtrimpro-refiereygana.firebasestorage.app/o/legal%2FPoli%CC%81ticas-De-Privacidad.pdf?alt=media&token=a0268cf7-8712-4f80-aed2-a6c0237f88f6"
        target="_blank"
        >Política de privacidad</a
      >
      -
      <a
        href="https://firebasestorage.googleapis.com/v0/b/xtrimpro-refiereygana.firebasestorage.app/o/legal%2FAviso-Legal.pdf?alt=media&token=d5d886e9-e844-4037-995c-8d39edbd7503"
        target="_blank"
        >Aviso legal</a
      >-
      <a
        href="https://firebasestorage.googleapis.com/v0/b/xtrimpro-refiereygana.firebasestorage.app/o/legal%2FDisclaimer.pdf?alt=media&token=52468f4b-ee80-4117-a77f-84d90ad0551f"
        target="_blank"
        >Disclaimer</a
      >
    </p>

    <!-- Copyright -->
    <p style="margin-top: 0px; font-size: 12px; margin-bottom: 10px">
      Copyright &copy; {{ year }}
      <a href="https://xtrimproec.com" target="_blank">- XtrimProEC</a>
    </p>
  </footer>
</template>

<script>
export default {
  name: "FooterComponent",
  computed: {
    year() {
      return new Date().getFullYear();
    },
  },
};
</script>

<style scoped>
.footerStyle {
  color: black !important;
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 20px 30px 20px;
}

.socialMedia {
  display: flex;
}
</style>
