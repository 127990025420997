<template>
  <div class="landing-header">
    <div v-if="!isMobile">
      <desktop-landing />
    </div>

    <div v-else>
      <mobile-landing />
    </div>

    <slider />

    <Ruleta />

    <div class="col-12 mb-4">
      <div class="text-center">
        <a href="/login" style="cursor: pointer">
          <img
            class="img-fluid responsive-image2"
            src="../../assets/img/Landing/quieres-20-banner.png"
            alt="XtrimPro Logo"
          />
        </a>
      </div>
    </div>

    <div
      style="
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
      "
      id="como-ganar"
    >
      <!-- Primera columna -->
      <div class="landing-container">
        <div class="contentHowToWin">
          <img src="../../assets/img/Landing/como-ganar.png" class="howToWin" />
          <p class="howToWinText">
            Con Xtrim PRO, ganas $20 por cada persona que refiera y contrate el
            servicio de internet. Es un proceso sencillo: agregas a tus
            referidos y, cuando aceptan, recibes tu dinero.
          </p>

          <p class="howToWinText">
            No hay límites en la cantidad de referidos, así que mientras más
            personas invites, más ganas.
            <!-- Además, todos necesitan internet en
            casa, lo que hace que sea fácil referirlo. -->
          </p>

          <p class="howToWinText">
            Empieza hoy y descubre lo simple que es ganar dinero extra.
            ¡Conviértete en un PRO refiriendo el mejor internet del país! 🚀
          </p>
        </div>

        <!-- Segunda columna -->
        <div
          style="
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
          "
        >
          <img
            src="../../assets/img/Landing/tu-tia.png"
            alt="XtrimPro Logo"
            class="img-fluid-aunt"
          />
          <p class="pinkPurpleText">
            TU TÍA YA GANÓ SUS PRIMEROS
            <span class="yellowSpan">$20</span> <br />
            <span class="bigSpan">¿Y TÚ? ¿PARA CUANDO?</span>
          </p>
        </div>
      </div>
    </div>

    <div class="col-12 mb-4">
      <div class="text-center">
        <img
          class="img-fluid responsive-image2"
          src="../../assets/img/Landing/banner-2.png"
          alt="XtrimPro Logo"
        />
      </div>
    </div>

    <div class="col-12 mb-4">
      <div class="text-center">
        <img
          class="img-fluid responsive-image2"
          src="../../assets/img/Landing/banner-3.png"
          alt="XtrimPro Logo"
        />
      </div>
    </div>

    <div class="col-12">
      <div class="text-center">
        <img
          class="img-fluid responsive-image2"
          src="../../assets/img/Landing/pilas-si-te-pasa.png"
          alt="XtrimPro Logo"
        />
      </div>
    </div>

    <div class="row justify-content-center mt-1 mb-5" id="preguntas">
      <div class="accordionContainer">
        <div class="accordion" id="faqAccordion">
          <!-- Pregunta 1 -->
          <div
            class="accordion-item mb-3"
            style="border: 1px solid #ffcc03; border-radius: 10px"
          >
            <h2 class="accordion-header" id="faq1">
              <button
                class="accordion-button fw-bold"
                style="
                  background-color: #820e82;
                  color: white;
                  font-size: 16px !important;
                  border-radius: 9px 9px 0 0;
                "
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapse1"
                aria-expanded="true"
                aria-controls="collapse1"
              >
                1. ¿Cómo puedo ganar dinero refiriendo Xtrim?
              </button>
            </h2>
            <div
              id="collapse1"
              class="accordion-collapse collapse show"
              aria-labelledby="faq1"
              data-bs-parent="#faqAccordion"
            >
              <div
                class="accordion-body"
                style="
                  background-color: #f3e5f5;
                  color: #6a1b9a;
                  border-radius: 0 0 9px 9px;
                "
              >
                Referir Xtrim es muy sencillo con XtrimPro:
                <ul style="margin-top: 10px">
                  <li>
                    Comparte el servicio con tus amigos, familiares o conocidos.
                  </li>
                  <li>
                    Si ellos contratan al servicio a través de tu referido,
                    ganas <strong>$20</strong> y así por cada nueva contratación
                    exitosa.
                  </li>
                </ul>
                ¡Entre más personas refieras, más dinero puedes ganar!
              </div>
            </div>
          </div>

          <!-- Pregunta 2 -->
          <div
            class="accordion-item mb-3"
            style="border: 1px solid #ffcc03; border-radius: 10px"
          >
            <h2 class="accordion-header" id="faq2">
              <button
                class="accordion-button collapsed fw-bold"
                style="
                  background-color: #820e82;
                  color: white;
                  font-size: 16px !important;
                  border-radius: 9px 9px 0 0;
                "
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapse2"
                aria-expanded="false"
                aria-controls="collapse2"
              >
                2. ¿Cuánto tiempo toma recibir el pago por mis referidos?
              </button>
            </h2>
            <div
              id="collapse2"
              class="accordion-collapse collapse"
              aria-labelledby="faq2"
              data-bs-parent="#faqAccordion"
            >
              <div
                class="accordion-body"
                style="
                  background-color: #f3e5f5;
                  color: #6a1b9a;
                  border-radius: 0 0 9px 9px;
                "
              >
                Una vez que la persona referida se suscriba y el servicio esté
                activo, tu recompensa de <strong>$20</strong> será procesada y
                entregada en un plazo máximo de <strong>5 días hábiles</strong>.
                ¡Así de rápido y directo a tu cuenta bancaria!
              </div>
            </div>
          </div>

          <!-- Pregunta 3 -->
          <div
            class="accordion-item"
            style="border: 1px solid #ffcc03; border-radius: 10px"
          >
            <h2 class="accordion-header" id="faq3">
              <button
                class="accordion-button collapsed fw-bold"
                style="
                  background-color: #820e82;
                  color: white;
                  font-size: 16px !important;
                  border-radius: 9px 9px 0 0;
                "
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapse3"
                aria-expanded="false"
                aria-controls="collapse3"
              >
                3. ¿Hay algún límite en la cantidad de personas que puedo
                referir?
              </button>
            </h2>
            <div
              id="collapse3"
              class="accordion-collapse collapse"
              aria-labelledby="faq3"
              data-bs-parent="#faqAccordion"
            >
              <div
                class="accordion-body"
                style="
                  background-color: #f3e5f5;
                  color: #6a1b9a;
                  border-radius: 0 0 9px 9px;
                "
              >
                ¡No, no hay límites! Puedes referir Xtrim a tantas personas como
                quieras. Por cada nueva contratación exitosa que logres,
                recibirás
                <strong>$20</strong>. Más recomendaciones = más dinero para ti.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="footer-background">
      <img
        src="../../assets/img/Landing/Footer.png"
        alt="Logo XtrimPro"
        class="background-image"
      />
      <div
        style="
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 12px;
        "
        id="contacto"
      >
        <div style="display: flex; width: 100%" class="footer-content">
          <div
            style="display: flex; justify-content: center; align-items: center"
          >
            <img
              src="../../assets/img/Xtrim/Xtrim-Pro-logo.png"
              alt="Logo XtrimPro"
              style="
                position: relative;
                max-height: 80px;
                height: auto;
                max-width: 50%;
              "
            />
          </div>
          <div
            style="
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
            "
          >
            <p
              style="
                margin: 0;
                font-family: Meloriac;
                font-size: 20px;
                font-weight: 300;
                letter-spacing: 1.5px;
                color: #ffcc03;
              "
            >
              Contáctanos
            </p>
            <div class="socialMedia">
              <a
                target="_blank"
                title="Facebook de XtrimPro"
                href="https://www.facebook.com/profile.php?id=100090370913056"
              >
                <i
                  class="fab fa-facebook-f redes"
                  style="color: white"
                  aria-hidden="true"
                ></i>
              </a>
              <a
                target="_blank"
                title="Instagram de XtrimPro"
                href="https://www.instagram.com/xtrimpro.ec/"
              >
                <i
                  class="fab fa-instagram redes"
                  style="color: white"
                  aria-hidden="true"
                ></i>
              </a>
              <a
                target="_blank"
                title="Email de XtrimPro"
                href="mailto:hola@xtrimproec.com?Subject=Tengo%20una%20pregunta"
              >
                <i
                  class="fas fa-envelope redes"
                  style="color: white"
                  aria-hidden="true"
                ></i>
              </a>
              <a
                target="_blank"
                title="Tiktok de XtrimPro"
                href="https://www.tiktok.com/@xtrimproec"
              >
                <i
                  class="fab fa-tiktok redes"
                  style="color: white"
                  aria-hidden="true"
                ></i>
              </a>
              <a
                target="_blank"
                title="Whatsapp de XtrimPro"
                href="https://api.whatsapp.com/send?phone=593995756176&text=Hola,%20deseo%20obtener%20informaci%C3%B3n%20sobre%20XtrimPro"
              >
                <i
                  class="fab fa-whatsapp redes"
                  style="color: white"
                  aria-hidden="true"
                ></i>
              </a>
            </div>
          </div>
        </div>
        <p
          style="
            margin-top: 10px;
            margin-bottom: 0;
            font-size: 12px;
            color: white;
            text-align: center;
          "
        >
          <a
            href="https://firebasestorage.googleapis.com/v0/b/xtrimpro-refiereygana.firebasestorage.app/o/legal%2FTe%CC%81rminos-Y-Condiciones.pdf?alt=media&token=4794f901-fd8f-4496-a2a2-e08448d270a6"
            target="_blank"
            style="color: white"
            >Términos y condiciones</a
          >
          -
          <a
            href="https://firebasestorage.googleapis.com/v0/b/xtrimpro-refiereygana.firebasestorage.app/o/legal%2FPoli%CC%81ticas-De-Privacidad.pdf?alt=media&token=a0268cf7-8712-4f80-aed2-a6c0237f88f6"
            target="_blank"
            style="color: white"
            >Política de privacidad</a
          >
          -
          <a
            href="https://firebasestorage.googleapis.com/v0/b/xtrimpro-refiereygana.firebasestorage.app/o/legal%2FAviso-Legal.pdf?alt=media&token=d5d886e9-e844-4037-995c-8d39edbd7503"
            target="_blank"
            style="color: white"
            >Aviso legal</a
          >-
          <a
            href="https://firebasestorage.googleapis.com/v0/b/xtrimpro-refiereygana.firebasestorage.app/o/legal%2FDisclaimer.pdf?alt=media&token=52468f4b-ee80-4117-a77f-84d90ad0551f"
            target="_blank"
            style="color: white"
            >Disclaimer</a
          >
        </p>
        <p
          style="
            margin-top: 0px;
            font-size: 12px;
            margin-bottom: 10px;
            font-weight: 700;
            width: fit-content;
            color: #ffcc03;
          "
        >
          Powered by
          <a
            href="https://xtrimproec.com"
            target="_blank"
            style="color: #ffcc03"
            >- PlanPro</a
          >
        </p>
      </div>
    </div>
    <whatsapp-float :on-dashboard="false" />
  </div>
</template>

<script>
/*eslint-disable */
import DesktopLanding from "./HeaderDesktop.vue";
import MobileLanding from "./HeaderMobile.vue";
import Slider from "./Slider.vue";
import Ruleta from "../RouletteLanding.vue";
import WhatsappFloat from "../WhatsappFloat.vue";

export default {
  name: "LandingHeader",
  components: {
    DesktopLanding,
    MobileLanding,
    Slider,
    Ruleta,
    WhatsappFloat,
  },

  data() {
    return {
      loading: true,
      isMobile: this.$isMobile,
    };
  },
  computed: {},
  watch: {},

  activated() {},
  mounted() {
    if (this.isMobile) {
      this.$eventBus.emit("activeShowMenu");
    }
  },
  methods: {},
};
</script>

<style scoped>
.position-relative {
  position: relative;
}

.position-absolute {
  position: absolute;
}

.top-50 {
  top: 50%;
}

.start-50 {
  left: 50%;
  width: 580px;
}

.translate-middle {
  transform: translate(-50%, -50%);
}

.img-fluid {
  max-width: 60%;
  height: auto;
}

.img-fluid-aunt {
  max-width: 60%;
  height: auto;
}

.responsive-image2 {
  max-width: auto;
  height: auto;
  margin: 0 auto;
}

.px-4 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.landing-container {
  width: 80%;
  display: flex;
  gap: 16px;
}

.howToWin {
  width: 100%;
}

.contentHowToWin {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50%;
  gap: 16px;
}

.howToWinText {
  margin: 0;
  font-size: 18px;
  font-weight: 700;
  width: 80%;
}

.footer-content {
  flex-direction: row;
  justify-content: space-around;
  gap: 12px;
}

.pinkPurpleText {
  padding: 12px 18px;
  background: rgb(170, 50, 189);
  background: linear-gradient(
    68deg,
    rgba(170, 50, 189, 1) 0%,
    rgba(217, 76, 225, 1) 100%
  );
  border: 1px solid #ec62ea;
  font-size: 16px;
  color: white;
  font-family: Meloriac;
  letter-spacing: 1.5px;
  text-align: center;
  border-radius: 28px;
  margin: 0 10px;
  width: fit-content;
}

.yellowSpan {
  color: #f9cc03;
}

.bigSpan {
  font-size: 24px;
}

.accordionContainer {
  width: 70%;
}

/* Responsivo para pantallas pequeñas */

@media (max-width: 1200px) {
  .landing-container {
    width: 80%;
    display: flex;
    flex-direction: column;
  }

  .howToWinText {
    text-align: center;
  }

  .contentHowToWin {
    width: 100%;
  }

  .img-fluid-aunt {
    max-width: 30%;
    height: auto;
  }
  .howToWin {
    width: 80%;
  }

  .howToWinText {
    margin: 0;
    font-size: 18px;
    font-weight: 700;
    width: 70%;
  }
}

@media (max-width: 900px) {
  .howToWin {
    width: 80%;
  }

  .howToWinText {
    margin: 0;
    font-size: 18px;
    font-weight: 700;
    width: 80%;
  }

  .accordionContainer {
    width: 85%;
  }
}

@media (max-width: 768px) {
  .responsive-image2 {
    max-width: 70%;
  }

  .px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .landing-container {
    width: 90%;
  }

  .img-fluid-aunt {
    max-width: 30%;
    height: auto;
  }
}

@media (max-width: 576px) {
  .responsive-image2 {
    max-width: 90%;
  }

  .px-4 {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  .landing-container {
    width: 95%;
  }

  .howToWin {
    width: 80%;
  }

  .howToWinText {
    margin: 0;
    font-size: 18px;
    font-weight: 700;
    width: 90%;
  }

  .footer-content {
    flex-direction: column-reverse;
    justify-content: center;
  }

  .pinkPurpleText {
    font-size: 14px;
  }

  .yellowSpan {
    color: #f9cc03;
  }

  .bigSpan {
    font-size: 18px;
  }
  .accordionContainer {
    width: 90%;
  }
}

.landing-header {
  background: radial-gradient(circle at top left, #bcabf7, transparent 50%),
    radial-gradient(circle at bottom right, #bcabf7, transparent 40%), #e8e1ff;
}

.footer-background {
  position: relative;
  width: 100%;
  overflow: hidden; /* Hide the overflow of the image */
  height: fit-content;
  padding: 20px;
}

.footer-background .background-image {
  position: absolute; /* Makes the background image layer behind everything */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures the image fills the div */
  z-index: 0; /* Ensures it is the bottom-most layer */
}

.footer-background p {
  color: white; /* Make content readable over the image */
  text-align: end;
}

.footer-background p,
.footer-background .socialMedia {
  position: relative;
  z-index: 2; /* Places the text and icons above the image */
  color: white; /* Ensures content is visible on the image */
}

/* General Accordion Styles */
.faq-accordion {
  background-color: #4a148c; /* Purple */
  border-radius: 10px;
  padding: 15px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}

/* Title Styles */
.faq-title {
  color: #ffcc03; /* Yellow */
  font-family: "Meloriac", sans-serif;
  font-size: 1.8rem;
  font-weight: bold;
  text-transform: uppercase;
}

/* Accordion Item Styles */
.faq-item {
  margin-bottom: 10px;
  border: none;
  border-radius: 8px;
}

/* Accordion Header Styles */
.faq-header {
  background-color: #7b1fa2; /* Lighter Purple */
  color: #ffffff;
  font-size: 1rem;
  font-weight: 600;
  padding: 12px;
  text-align: left;
  border-radius: 8px;
  transition:
    background-color 0.3s ease,
    transform 0.2s ease;
}

.faq-header:hover {
  background-color: #6a1b9a; /* Hover effect */
  transform: scale(1.02);
}

.faq-header.collapsed {
  background-color: #4a148c; /* Darker Purple */
}

/* Accordion Body Styles */
.faq-body {
  background-color: #fafafa; /* Light background for contrast */
  color: #333;
  padding: 15px;
  border-radius: 8px;
  font-size: 0.95rem;
  line-height: 1.5;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
}

/* Accordion Collapse Transition */
.accordion-collapse {
  transition: height 0.3s ease;
}

/* List Style for Body */
.faq-body ul {
  padding-left: 20px;
}

.faq-body ul li {
  margin-bottom: 5px;
}

/* Responsive Design */
@media (max-width: 768px) {
  .faq-header {
    font-size: 0.9rem;
  }

  .faq-body {
    font-size: 0.85rem;
  }
}
</style>
