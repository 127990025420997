export default function useComp() {
  function logout(userLoginId, tokenAuth, isDashboard) {
    this.$swal({
      title: "¿Seguro que deseas cerrar sesión?",
      icon: "question",
      width: "400px",
      showCancelButton: true,
      confirmButtonText: "Cerrar sesión",
      cancelButtonText: "Cancelar",
      customClass: {
        confirmButton: "btn btn-primary",
        cancelButton: "btn btn-outline-secondary ml-1",
      },
      backdrop: true,
      buttonsStyling: false,
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return this.$https
          .post("/access/logout/", {
            tokenAuth: tokenAuth,
            userLoginId: userLoginId,
          })
          .then((response) => {
            return response.data;
          })
          .catch((error) => {
            this.$swal.showValidationMessage(error);
          });
      },
      allowOutsideClick: () => !this.$swal.isLoading(),
    }).then((result) => {
      this.$swal.close();

      if (result.isConfirmed) {
        if (result.value.code == 200) {
          this.$store.dispatch("clearAllData");

          if (isDashboard) {
            this.$router.push({ name: "Signin" });
          } else {
            location.reload();
          }

          this.$toast.success("Sesión cerrada exitosamente", {
            position: "top-right",
            max: 10,
          });
        } else {
          this.$toast.error(result.value.message, {
            position: "top-right",
            max: 10,
          });

          if (result.value.code == 401) {
            this.$store.dispatch("clearAllData");

            // Redirect to login page
            this.$router.push({ name: "Signin" });
          }
        }
      }
    });
  }
  return {
    logout,
  };
}
