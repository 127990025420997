<template>
  <div class="row">
    <div class="col-lg-12 col-md-12 col-12 col-sm-12 col-xl-12" align="center">
      <h6
        style="font-size: 11px; margin-bottom: 20px; color: white"
        class="text-center"
      >
        Saldo disponible para retirar.
      </h6>
      <Skeletor
        v-if="loading"
        height="40"
        width="25%"
        style="margin-bottom: 20px; margin-top: 20px"
      />
      <h6
        v-else
        style="
          font-weight: bold;
          margin-bottom: 20px;
          margin-top: 20px;
          font-size: 40px;
        "
        class="text-center totalBalanceCard"
      >
        <span style="font-size: 25px">$</span>
        {{ Number(availableBalance).toFixed(2) }}
      </h6>
      <h6
        style="
          font-size: 11px;
          margin-bottom: 8px;
          line-height: 15px;
          color: white;
        "
        class="text-center"
      >
        El pago se realizará en máximo 5 días laborables.
      </h6>
      <h6
        style="
          font-size: 11px;
          margin-bottom: 8px;
          line-height: 15px;
          color: white;
        "
        class="text-center"
      >
        Valor mínimo de retiro: $20.00
      </h6>
      <h6
        style="
          font-size: 11px;
          margin-bottom: 8px;
          line-height: 15px;
          color: white;
        "
        class="text-center"
      >
        Puedes retirar tu saldo una vez completada tu primera venta
      </h6>

      <div class="col-lg-12 col-md-12 col-12 col-sm-12 col-xl-12 text-center">
        <button
          v-if="userBankAccountId"
          class="btn btn-yellow-normal btn-sm"
          style="width: 100%; margin-top: 12px"
          :disabled="isDisabled"
          type="button"
          @click="withdrawBalance"
        >
          Retirar dinero
        </button>
        <router-link
          class="btn btn-primary btn-sm"
          style="width: 100%; display: inline-block; text-align: center"
          :to="'/dashboard/banco'"
          v-else
        >
          Agregar cuenta bancaria
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import Saldo from "./Saldo.vue";

export default {
  components: {
    Saldo,
  },
  props: ["userId", "tokenAuth", "closeWithdrawModal"],
  data() {
    return {
      isDisabled: true,
      loading: true,
      retiroCash: "",
      availableBalance: "0",
      // sales: 0,
      userBankAccountId: this.$store.state.user.userBankAccountId,
    };
  },
  computed: {},
  watch: {},
  mounted() {
    this.$eventBus.on("reiniciarSaldos", () => {
      this.componentKeySaldos += 1;
    });
    this.fetchBalance();
  },
  methods: {
    fetchBalance() {
      this.isDisabled = true;
      this.$https
        .post("/profile/getBalance/", {
          tokenAuth: this.tokenAuth,
          userId: this.userId,
        })
        .then((response) => {
          this.loading = false;
          this.availableBalance = response.data.balance.balanceTotal;
          // this.sales = response.data.balance.sales;
          if (response.data.balance.balanceTotal >= 20) {
            this.isDisabled = false;
          }
          // if (
          //   response.data.balance.balanceTotal >= 20 &&
          //   response.data.balance.sales > 0
          // ) {
          //   this.isDisabled = false;
          // }
        })
        .catch((error) => {
          if (error.status === 401) {
            this.$store.dispatch("clearAllData");
            this.$toast.error("Sesión expirada. Inicie sesión nuevamente", {
              position: "top-right",
              max: 10,
            });
            this.closeWithdrawModal();
            this.$router.push({ name: "Signin" });
          } else if (error.status === 404) {
            this.$toast.error("Saldo no encontrado", {
              position: "top-right",
              max: 10,
            });
            this.closeWithdrawModal();
          } else {
            this.$toast.error("Ocurrió un error inesperado", {
              position: "top-right",
              max: 10,
            });
            this.closeWithdrawModal();
          }
        });
    },

    withdrawBalance() {
      if (this.availableBalance === 0) {
        this.$toast.error("No existe dinero para retirar", {
          position: "top-right",
          max: 10,
        });
      } else {
        this.$swal({
          title: `¿Seguro que deseas hacer un retiro por $${parseFloat(
            this.availableBalance,
          ).toFixed(2)}?`,
          icon: "warning",
          width: "400px",
          showCancelButton: true,
          confirmButtonText: "Confirmar",
          cancelButtonText: "Cancelar",
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-secondary btn-outline-secondary ml-1",
          },
          buttonsStyling: false,
          showLoaderOnConfirm: true,
          preConfirm: () => {
            this.isDisabled = true;
            return this.$https
              .post("/banks/withdrawBalance/", {
                userId: this.userId,
                tokenAuth: this.tokenAuth,
                userBankAccountId: this.userBankAccountId,
              })
              .then((response) => {
                return response;
              })
              .catch((error) => {
                // this.$swal.showValidationMessage(error);
                console.log("error", error);
              });
          },
          allowOutsideClick: () => !this.$swal.isLoading(),
          backdrop: true,
        }).then((result) => {
          this.$swal.close();
          if (result.isConfirmed) {
            this.isDisabled = false;

            if (result.value.data.code == 200) {
              this.$toast.success(result.value.data.message, {
                position: "top-right",
                max: 10,
              });

              this.$eventBus.emit("closeModalRetiro");
              this.$eventBus.emit("reiniciarSaldos");
              this.isDisabled = false;
            } else if (result.value.data.code == 404) {
              this.$eventBus.emit("closeModalRetiro");
              this.$toast.error(result.value.data.message, {
                position: "top-right",
                max: 10,
              });
              this.isDisabled = false;
              if (
                result.value.data.message !==
                "Ya existe una solicitud pendiente de retiro"
              ) {
                this.$router.push({ name: "banco" });
              }
            } else {
              this.$toast.error(result.value.data.message, {
                position: "top-right",
                max: 10,
              });
              this.isDisabled = false;
            }
          }
        });
      }
    },
  },
};
</script>

<style scoped>
.totalBalanceCard {
  background: rgb(168, 53, 153);
  background: linear-gradient(
    68deg,
    rgba(168, 53, 153, 1) 0%,
    rgba(222, 79, 217, 1) 100%
  );
  color: white;
  border: 2px solid #f65bf0;
  border-radius: 16px;
  padding: 3px 10px;
  width: fit-content;
}
</style>
