<template>
  <div class="card" style="margin-bottom: 10px">
    <div class="card-body">
      <div class="row">
        <div
          class="col-lg-12 col-md-12 col-12 col-sm-12 col-xl-12"
          style="
            margin-bottom: 10px;
            justify-content: space-between;
            display: flex;
          "
        >
          <h5
            class="card-title mb-0 text-uppercase font-weight-bold"
            style="font-size: 12px"
          >
            MI EQUIPO TERCER NIVEL
          </h5>
          <div
            v-if="!loading"
            @click="refresh"
            style="cursor: pointer; position: relative; top: -5px"
          >
            <i
              style="font-size: 6px"
              class="fas fa-sync text-sm opacity-10"
            ></i>
          </div>
        </div>

        <div class="col-lg-12 col-md-12 col-12">
          <Skeletor
            v-if="loading"
            :shimmer="true"
            height="38"
            width="50%"
            style="margin-bottom: 0px"
          />
          <p v-else class="font-weight-bolder priceCard">
            <span style="font-size: 14px">$</span>{{ amount.toFixed(2) }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
export default {
  components: {},
  props: ["userId", "tokenAuth", "balanceLevel3", "loadingBalance"],
  data() {
    return {
      amount: this.balanceLevel3 ?? 0,
    };
  },
  computed: {
    loadingBalance() {
      return this.loadingBalance;
    },
    amount() {
      return this.balanceLevel2;
    },
  },
  watch: {},
  mounted() {},
  methods: {
    referidos() {
      this.$toast.error("Funcionalidad no disponible por el momento", {
        position: "top-right",
        max: 10,
      });
    },
    refresh() {
      this.$eventBus.emit("reiniciarTercerNivel");
    },
  },
};
</script>

<style lang="scss"></style>
