<template>
  <div class="card container" style="margin-bottom: 10px">
    <div
      style="
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
      "
    >
      <div
        style="
          margin-bottom: 10px;
          justify-content: center;
          display: flex;
          width: 100%;
        "
      >
        <h5
          class="card-title mb-0 text-uppercase"
          style="
            font-size: 12px;
            background-color: #7d0c7e;
            margin: 0;
            padding: 4px 8px;
            border-radius: 8px;
            color: white;
            font-weight: 900;
          "
        >
          MIS VENTAS
        </h5>
        <!-- <div
          v-if="!loading"
          @click="refresh"
          style="cursor: pointer; position: relative; top: -5px"
        >
          <i style="font-size: 6px" class="fas fa-sync text-sm opacity-10"></i>
        </div> -->
      </div>

      <div style="width: 100%">
        <div
          v-if="loadingBalance"
          style="display: flex; justify-content: center; align-items: center"
        >
          <Skeletor
            :shimmer="true"
            height="38"
            width="50%"
            style="margin-bottom: 0px"
          />
        </div>
        <div
          v-else
          style="display: flex; justify-content: center; align-items: center"
        >
          <img src="../../assets/img/Xtrim/billete.png" style="height: 40px" />
          <p class="font-weight-bolder priceCard">
            <span style="font-size: 20px">$</span>{{ amount.toFixed(2) }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
export default {
  components: {},
  props: ["userId", "tokenAuth", "balanceLevel0", "loadingBalance"],
  data() {
    return {
      amount: this.balanceLevel0 ?? 0,
    };
  },
  computed: {
    loadingBalance() {
      return this.loadingBalance;
    },
    amount() {
      return this.balanceLevel0;
    },
  },
  watch: {},
  mounted() {
    // this.getReferidos();
  },
  methods: {
    referidos() {
      this.$toast.error("Funcionalidad no disponible por el momento", {
        position: "top-right",
        max: 10,
      });
    },
    refresh() {
      this.$eventBus.emit("reiniciarMisVentas");
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  background: rgb(247, 152, 242);
  background: linear-gradient(
    68deg,
    rgba(247, 152, 242, 1) 0%,
    rgba(250, 212, 248, 1) 100%
  );
  border: 2px solid #f65bf0;
  border-radius: 24px !important;
  padding: 10px;
  height: 90%;
}
</style>
