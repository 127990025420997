<template>
  <div>
    <modal-photo
      v-if="showModalPhoto"
      :key="componentKeyModalPhoto"
      :user-id="userId"
      :token-auth="tokenAuth"
      :image="image"
    />
    <div class="row">
      <div class="col-12 col-sm-12 col-md-2 col-lg-3 col-xl-3"></div>
      <div class="col-12 col-sm-12 col-md-8 col-lg-6 col-xl-6">
        <div
          class="card"
          style="
            background: linear-gradient(
              68deg,
              rgb(197, 30, 177) 0%,
              rgb(173, 40, 156)
            );
            border: 3px solid #f685ed;
          "
        >
          <div class="card-body">
            <div class="row" style="margin-bottom: 15px">
              <div
                style="position: relative"
                class="col-12 col-sm-12 col-md-12 col-xl-12 col-lg-12"
                align="center"
              >
                <v-lazy-image
                  :src="photo"
                  @error="photo = '/img/user.svg'"
                  class="rounded-circle img_profile"
                  style="border: 4px solid #ffcc03; width: 100px"
                />

                <input
                  type="file"
                  id="upload"
                  ref="profilePhoto"
                  accept="image/jpeg, image/jpg, image/png"
                  @change="loadImage($event)"
                  hidden
                />
                <label for="upload" class="camera_photo">
                  <i style="margin: auto" class="fas fa-camera"></i
                ></label>
              </div>

              <div
                class="col-12 col-sm-12 col-md-12 col-xl-12 col-lg-12"
                align="center"
              >
                <p
                  style="
                    margin-bottom: 0px;
                    margin-top: 15px;
                    line-height: 18px;
                    text-align: center;
                    font-size: 15px;
                    font-weight: bold;
                    color: white;
                  "
                >
                  {{ names + " " + lastnames }}
                </p>
                <p
                  style="
                    margin-bottom: 15px;
                    text-align: center;
                    font-size: 13px;
                    color: white;
                  "
                >
                  {{ dni }}
                </p>

                <form role="form" autocomplete="off" @submit.prevent="onSubmit">
                  <div class="row">
                    <div
                      class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                      style="margin-bottom: 10px; text-align: left"
                    >
                      <label
                        for="example-text-input"
                        class="form-control-label"
                        style="
                          font-weight: bold;
                          font-size: 12px;
                          text-align: left;
                          color: #ffcc03;
                        "
                        >Email (*):</label
                      >
                      <Skeletor
                        v-if="loading"
                        :shimmer="true"
                        height="40"
                        width="100%"
                      />
                      <input
                        v-else
                        placeholder="Ingrese su email"
                        class="form-control col-lg-12"
                        required
                        disabled
                        type="email"
                        autocomplete="off"
                        v-model="email"
                      />
                      <!-- <p style="margin: 0px; text-align: left">
                        {{ email }}
                      </p> -->
                    </div>
                    <div class="inputFieldLabel">
                      <label
                        for="example-text-input"
                        class="form-control-label"
                        style="
                          align-self: flex-start;
                          margin-bottom: 4px;
                          color: #ffcc03;
                        "
                        >Número celular (*):</label
                      >
                      <Skeletor
                        v-if="loading"
                        :shimmer="true"
                        height="40"
                        width="100%"
                      />
                      <MazPhoneNumberInput
                        v-else
                        type="tel"
                        default-country-code="EC"
                        style="width: 100%"
                        no-flags
                        no-example
                        required
                        show-code-on-list
                        size="md"
                        :disabled="isDisabled"
                        v-model="phone"
                        @update="results = $event"
                        :translations="{
                          countrySelector: {
                            placeholder: 'Código',
                            error: 'Elija un pais',
                          },
                          phoneInput: {
                            placeholder: 'Nro. celular',
                            example: 'Ejemplo:',
                          },
                        }"
                      />
                    </div>

                    <div
                      style="
                        display: flex;
                        width: 100%;
                        justify-content: flex-end;
                      "
                    >
                      <Skeletor
                        v-if="loading"
                        :shimmer="true"
                        height="40"
                        width="100%"
                      />
                      <button
                        id="btn_guardar"
                        v-else
                        type="submit"
                        class="btn btn-yellow-normal"
                        style="width: fit-content; margin-top: 15px"
                        :disabled="isDisabled || !isValid"
                      >
                        Actualizar
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12 col-sm-12 col-md-2 col-lg-3 col-xl-3"></div>
    </div>
  </div>
</template>

<script>
/*eslint-disable */
import ModalPhoto from "./ModalPhoto.vue";
export default {
  name: "EdicionPerfil",
  components: {
    ModalPhoto,
  },
  data() {
    let data = this.$store.state.user;
    let photo = "";

    if (data.photo === "") {
      photo = "/img/user.svg";
    } else {
      photo = data.photo;
    }

    return {
      userId: data.userId,
      phone: data.phone,
      tokenAuth: this.$store.state.tokenAuth,
      dni: data.dni,
      displayName: data.displayName,
      names: data.names,
      lastnames: data.lastnames,
      photo: photo,
      showModalPhoto: false,
      loading: true,
      isDisabled: false,
      email: "",
      componentKeyModalPhoto: 100,
      type: "",
      image: {
        src: null,
        type: null,
      },
      results: {},
    };
  },
  activated() {
    if (this.isMobile) {
      this.$eventBus.emit("inactiveShowMenu");
    }
  },
  computed: {
    isValid() {
      return this.results.isValid;
    },
  },
  methods: {
    getProfile() {
      this.loading = true;

      this.$https
        .post("/profile/getProfile/", {
          tokenAuth: this.tokenAuth,
          userId: this.userId,
        })
        .then((response) => {
          this.email = response.data.email;
          this.loading = false;
        })
        .catch((error) => {
          if (error.status === 401) {
            this.$store.dispatch("clearAllData");
            this.$toast.error("Sesión expirada. Inicie sesión nuevamente", {
              position: "top-right",
              max: 10,
            });
            this.$router.push({ name: "Signin" });
          } else {
            this.$toast.error("Ocurrió un error inesperado", {
              position: "top-right",
              max: 10,
            });
          }
          this.loading = false;

          // this.getProfile();
        });
    },

    onChange2(event) {
      this.genero = event.target.value;
    },

    onChange1(event) {
      this.estadoCivil = event.target.value;
    },

    onSubmit(event) {
      event.preventDefault();

      if (!this.results.isValid) {
        this.$toast.error("Ingresa un número celular válido", {
          position: "top-right",
          max: 10,
        });
        return;
      }

      this.isDisabled = true;

      document.getElementById("btn_guardar").innerHTML =
        '<i class="fas fa-circle-notch fa-spin"></i> Actualizando';

      this.$https
        .post("/profile/editProfile/", {
          tokenAuth: this.tokenAuth,
          userId: this.userId,
          email: this.email,
          phone: this.phone,
        })
        .then((response) => {
          document.getElementById("btn_guardar").innerHTML = "Actualizar";
          this.isDisabled = false;
          if (response.data.code == 200) {
            this.$toast.success(response.data.message, {
              position: "top-right",
              max: 10,
            });
          } else {
            this.$toast.error(response.data.messag, {
              position: "top-right",
              max: 10,
            });

            if (response.data.code == 401) {
              this.$store.dispatch("clearAllData");

              // Redirect to login page
              this.$router.push({ name: "login" });
            }
          }
        })
        .catch((error) => {
          this.$toast.error(error, {
            position: "top-right",
            max: 10,
          });
        });
    },

    loadImage(event) {
      if (
        event.target.files[0].type === "image/jpeg" ||
        event.target.files[0].type === "image/jpg" ||
        event.target.files[0].type === "image/png"
      ) {
        this.type = "image/jpeg";

        const FileSizeBanner = event.target.files[0].size / 1024 / 1024;
        // let foto=event.target.files[0];

        if (FileSizeBanner > 5) {
          this.$refs.profilePhoto.value = null;

          this.$toast.error("La imagen debe pesar menos de 5mb", {
            position: "bottom-right",
            max: 10,
          });
        } else {
          // Reference to the DOM input element
          const { files } = event.target;
          // Ensure that you have a file before attempting to read it
          if (files && files[0]) {
            // 1. Revoke the object URL, to allow the garbage collector to destroy the uploaded before file
            if (this.image.src) {
              URL.revokeObjectURL(this.image.src);
            }
            // 2. Create the blob link to the file to optimize performance:
            const blob = URL.createObjectURL(files[0]);

            // 3. The steps below are designated to determine a file mime type to use it during the
            // getting of a cropped image from the canvas. You can replace it them by the following string,
            // but the type will be derived from the extension and it can lead to an incorrect result:
            //
            // this.image = {
            //    src: blob;
            //    type: files[0].type
            // }

            // Create a new FileReader to read this image binary data
            const reader = new FileReader();
            // Define a callback function to run, when FileReader finishes its job
            reader.onload = (e) => {
              // Note: arrow function used here, so that "this.image" refers to the image of Vue component
              this.image = {
                // Set the image source (it will look like blob:http://example.com/2c5270a5-18b5-406e-a4fb-07427f5e7b94)
                src: blob,
                // Determine the image type to preserve it during the extracting the image from canvas:
                type: this.type,
              };
            };
            // Start the reader job - read file as a data url (base64 format)
            reader.readAsArrayBuffer(files[0]);

            this.showModalPhoto = true;
          }
        }
      } else {
        // this.$refs.profilePhoto.value = null;
        if (this.$refs.profilePhoto) {
          this.$refs.profilePhoto.value = null;
        }

        this.$toast.error("El archivo debe tener una extensión JPG/JPEG", {
          position: "bottom-right",
          max: 10,
        });
      }
    },
  },
  mounted() {
    this.getProfile();

    this.$eventBus.on("reiniciarModalPhoto", () => {
      if (this.$refs.profilePhoto) {
        this.$refs.profilePhoto.value = null;
      }

      this.componentKeyModalPhoto += 1;
      this.showModalPhoto = false;
    });

    this.$eventBus.on("updateFotoPerfil", (url) => {
      this.photo = url;
    });
  },
};
</script>

<style scoped>
.inputFieldLabel {
  margin-bottom: 2px;
  display: flex;
  flex-direction: column;
  width: 100%;
}
.form-control {
  border: 2px solid #f9cc03;
}
:deep(.m-phone-number-input.--responsive .m-input-wrapper) {
  border: 2px solid #f9cc03 !important;
}
</style>
