<template>
  <div>
    <VueFinalModal
      v-model="showModal"
      :esc-to-close="true"
      @closed="closed"
      class="modal-container"
      content-class="modal-content2"
    >
      <div class="modal__content">
        <div class="row">
          <div class="col-11 col-sm-11 col-md-11 col-xl-11 col-lg-11"></div>

          <div
            class="col-1 col-sm-1 col-md-1 col-xl-1 col-lg-1"
            @click="closed"
          >
            <i
              class="fas fa-times-square close_modal"
              style="color: #ffffffd0"
            ></i>
          </div>
        </div>

        <form-venta
          v-if="showModal"
          :key="componentKeyFormVenta"
          :promo-code="promoCode"
          :token-auth="tokenAuth"
          :on-dashboard="true"
        />
      </div>
    </VueFinalModal>

    <VueFinalModal
      v-model="showModal2"
      :esc-to-close="true"
      @closed="closeWithdrawModal"
      class="modal-container"
      content-class="modal-content popupStyle"
      header-class="modal-header"
    >
      <div class="modal__content">
        <div class="row">
          <div class="col-11 col-sm-11 col-md-11 col-xl-11 col-lg-11"></div>

          <div
            class="col-1 col-sm-1 col-md-1 col-xl-1 col-lg-1"
            @click="closeWithdrawModal"
          >
            <i class="fas fa-times-square close_modal" style="color: white" />
          </div>
        </div>

        <retiro-cash
          v-if="showModal2"
          :key="componentKeyFormRetiro"
          :user-id="userId"
          :token-auth="tokenAuth"
          :user-bank-account-id="userBankAccountId"
          :close-withdraw-modal="closeWithdrawModal"
        />
      </div>
    </VueFinalModal>

    <div class="row">
      <div style="width: 100%; padding-bottom: 12px" v-if="!isMobile">
        <swiper
          :slides-per-view="3"
          :space-between="15"
          :centered-slides="false"
          :navigation="false"
          :pagination="false"
          :breakpoints="{
            200: {
              slidesPerView: 1.1,
            },
            400: {
              slidesPerView: 1.1,
            },
            550: {
              slidesPerView: 2.1,
            },
            768: {
              slidesPerView: 3,
            },
            1024: {
              slidesPerView: 3,
            },
            1200: {
              slidesPerView: 3,
            },
          }"
        >
          <swiper-slide>
            <img
              src="../../assets/img/Xtrim/refiere-y-gana.png"
              style="width: 100%; object-fit: cover"
              @click="registerReferral"
            />
          </swiper-slide>
          <swiper-slide>
            <img
              src="../../assets/img/Xtrim/arma-tu-equipo.png"
              style="width: 100%; object-fit: cover"
              @click="copyInputValue('link1')"
            />
          </swiper-slide>
          <swiper-slide>
            <a :href="this.helpText" target="_blank">
              <img
                src="../../assets/img/Xtrim/contacto.png"
                style="width: 100%; object-fit: cover"
              />
            </a>
          </swiper-slide>
        </swiper>
      </div>

      <div style="width: 100%">
        <swiper
          :slides-per-view="4"
          :space-between="15"
          :centered-slides="false"
          :navigation="false"
          :pagination="false"
          :breakpoints="{
            200: {
              slidesPerView: 1.1,
            },
            400: {
              slidesPerView: 1.1,
            },
            550: {
              slidesPerView: 2.5,
            },
            768: {
              slidesPerView: 3.1,
            },
            1024: {
              slidesPerView: 3.5,
            },
            1200: {
              slidesPerView: 4,
            },
          }"
        >
          <swiper-slide>
            <saldo
              :key="componentKeySaldos"
              :user-id="userId"
              :token-auth="tokenAuth"
              :balance-total="balanceTotal"
              :loading-balance="loadingBalance"
            />
          </swiper-slide>
          <swiper-slide>
            <ventas-directas
              :key="componentKeySaldos"
              :user-id="userId"
              :token-auth="tokenAuth"
              :balance-level0="balanceLevel0"
              :loading-balance="loadingBalance"
            />
          </swiper-slide>
          <swiper-slide>
            <referidos-primer-nivel
              :key="componentKeySaldos"
              :user-id="userId"
              :token-auth="tokenAuth"
              :balance-level1="balanceLevel1"
              :loading-balance="loadingBalance"
            />
          </swiper-slide>
          <swiper-slide>
            <referidos-segundo-nivel
              :key="componentKeySaldos"
              :user-id="userId"
              :token-auth="tokenAuth"
              :balance-level2="balanceLevel2"
              :loading-balance="loadingBalance"
            />
          </swiper-slide>
          <!-- <swiper-slide>
            <referidos-tercer-nivel :key="componentKeyTercerNivel" :user-id="userId" :token-auth="tokenAuth" />
          </swiper-slide> -->
        </swiper>
      </div>

      <div style="width: 100%" v-if="isMobile">
        <swiper
          :slides-per-view="3"
          :space-between="15"
          :centered-slides="false"
          :navigation="false"
          :pagination="false"
          :breakpoints="{
            200: {
              slidesPerView: 1.1,
            },
            400: {
              slidesPerView: 1.1,
            },
            550: {
              slidesPerView: 2.1,
            },
            768: {
              slidesPerView: 3,
            },
            1024: {
              slidesPerView: 3,
            },
            1200: {
              slidesPerView: 3,
            },
          }"
        >
          <swiper-slide>
            <img
              src="../../assets/img/Xtrim/refiere-y-gana.png"
              style="width: 100%; object-fit: cover"
            />
          </swiper-slide>
          <swiper-slide>
            <img
              src="../../assets/img/Xtrim/arma-tu-equipo.png"
              style="width: 100%; object-fit: cover"
              @click="copyInputValue(link1)"
            />
          </swiper-slide>
          <swiper-slide>
            <img
              src="../../assets/img/Xtrim/contacto.png"
              style="width: 100%; object-fit: cover"
            />
          </swiper-slide>
        </swiper>
      </div>

      <div
        class="col-12 col-sm-12 col-lg-12 col-md-12 col-xl-4"
        v-if="!isMobile"
      >
        <div class="row">
          <div
            class="col-12 col-sm-12 col-lg-6 col-md-4 col-lg-12 col-xl-12"
            style="display: flex; justify-content: center; align-items: center"
          >
            <button type="button" class="pinkPurpleText" @click="goToRoulette">
              ¡GIRA PARA GANAR!
            </button>
            <div
              style="position: relative; cursor: pointer"
              @click="goToRoulette"
            >
              <img
                src="../../assets/img/Xtrim/arrow-gold.png"
                style="
                  position: absolute;
                  height: 10px;
                  left: 50%;
                  transform: translateX(-50%);
                  z-index: 2;
                  top: 2px;
                  transition:
                    width 0.3s,
                    height 0.3s;
                "
              />
              <img
                src="../../assets/img/Xtrim/ruleta-plain.png"
                style="height: 75px"
                class="rotating-image"
              />
            </div>
          </div>

          <div class="col-12 col-sm-12 col-lg-6 col-md-4 col-xl-12">
            <link-venta :promo-code="promoCode" :is-mobile="isMobile" />
          </div>
          <div class="col-12 col-sm-12 col-lg-6 col-md-4 col-xl-12">
            <link-referido :promo-code="promoCode" :is-mobile="isMobile" />
          </div>
        </div>
      </div>

      <div
        class="col-12 col-sm-12 col-lg-4 col-md-12 col-xl-4"
        v-else
        style="margin-bottom: 10px"
      >
        <div
          class="col-12 col-sm-12 col-lg-6 col-md-4 col-lg-12 col-xl-12"
          style="display: flex; justify-content: center; align-items: center"
        >
          <button type="button" class="pinkPurpleText" @click="goToRoulette">
            ¡GIRA PARA GANAR!
          </button>
          <div
            style="position: relative; cursor: pointer"
            @click="goToRoulette"
          >
            <img
              src="../../assets/img/Xtrim/arrow-gold.png"
              style="
                position: absolute;
                height: 10px;
                left: 50%;
                transform: translateX(-50%);
                z-index: 2;
                top: 2px;
                transition:
                  width 0.3s,
                  height 0.3s;
              "
            />
            <img
              src="../../assets/img/Xtrim/ruleta-plain.png"
              style="height: 75px"
              class="rotating-image"
            />
          </div>
        </div>
        <swiper
          :slides-per-view="3"
          :space-between="15"
          :centered-slides="false"
          :navigation="false"
          :pagination="false"
          :breakpoints="{
            200: {
              slidesPerView: 1.1,
            },
            400: {
              slidesPerView: 1.1,
            },
            768: {
              slidesPerView: 1.1,
            },
            1024: {
              slidesPerView: 1.1,
            },
            1200: {
              slidesPerView: 1.1,
            },
          }"
        >
          <swiper-slide>
            <link-venta :promo-code="promoCode" :is-mobile="isMobile" />
          </swiper-slide>
          <swiper-slide>
            <link-referido :promo-code="promoCode" :is-mobile="isMobile" />
          </swiper-slide>
        </swiper>
      </div>

      <div
        class="col-12 col-sm-12 col-lg-12 col-md-12 col-xl-8"
        style="margin-top: 0px; overflow: auto"
      >
        <listado-ventas-dashboard
          :key="componentKeyListadoVentas"
          :user-id="userId"
          :token-auth="tokenAuth"
          :onDashboard="true"
          :registerReferral="registerReferral"
        />
      </div>

      <!-- //espacio para whatsapp -->
    </div>
  </div>
</template>
<script>
/*eslint-disable */
import Saldo from "./Saldo.vue";
import LinkReferido from "./LinkReferido.vue";
import LinkVenta from "./LinkVenta.vue";
import VentasDirectas from "./VentasDirectas.vue";
import ReferidosPrimerNivel from "./ReferidosPrimerNivel.vue";
import ReferidosSegundoNivel from "./ReferidosSegundoNivel.vue";
import ReferidosTercerNivel from "./ReferidosTercerNivel.vue";
import RegistroReferido from "./RegistroReferido.vue";
import ListadoVentasDashboard from "./ListadoVentasDashboard.vue";
import FormVenta from "../FormVenta";
import RetiroCash from "./RetiroCash.vue";
import { VueFinalModal } from "vue-final-modal";
import "vue-final-modal/style.css";
import WhatsappFloat from "../WhatsappFloat.vue";

import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";
export default {
  name: "dashboard-default",
  data() {
    let datos = this.$store.state.user;

    return {
      showModal: false,
      showModal2: false,
      isMobile: this.$isMobile,
      loading: true,
      loadingBalance: true,
      balanceLevel0: 0,
      balanceLevel1: 0,
      balanceLevel2: 0,
      balanceTotal: 0,
      componentKeySaldos: 0,
      componentKeyMisVentas: 0,
      componentKeyPrimerNivel: 100,
      componentKeySegundoNivel: 200,
      componentKeyTercerNivel: 300,
      componentKeyListadoVentas: 400,
      componentKeyFormVenta: 500,
      componentKeyFormRetiro: 600,
      promoCode: datos.promoCode,
      userId: datos.userId,
      displayName: datos.displayName,
      tokenAuth: this.$store.state.tokenAuth,
      userBankAccountId: datos.userBankAccountId,
      link:
        "Hola soy " +
        this.formatText(datos.names) +
        " " +
        datos.lastnames +
        " y te invito a formar parte de XTRIMPRO y ganar dinero refiriendo el internet de XTRIM, Registrate en el siguiente link: www." +
        window.location.host +
        "/invitacion/" +
        datos.promoCode,
      helpText:
        "https://api.whatsapp.com/send?phone=593995756176&text=Hola,%20soy%20" +
        this.formatText(datos.namesList[0]) +
        " " +
        this.formatText(datos.lastnamesList[0]) +
        "%20y%20deseo%20obtener%20ayuda%20en%20XTRIMPRO",
      link1: "www." + window.location.host + "/invitacion/" + datos.promoCode,
    };
  },
  components: {
    Saldo,
    VueFinalModal,
    FormVenta,
    LinkReferido,
    LinkVenta,
    RegistroReferido,
    ReferidosPrimerNivel,
    ReferidosSegundoNivel,
    ReferidosTercerNivel,
    ListadoVentasDashboard,
    RetiroCash,
    Swiper,
    SwiperSlide,
    VentasDirectas,
    WhatsappFloat,
  },

  methods: {
    registerReferral() {
      this.$eventBus.emit("showModalVenta");
    },
    copyInputValue(model) {
      const inputElement = document.getElementById(model);
      inputElement.select();
      document.execCommand("copy");
      inputElement.setSelectionRange(0, 0);
      this.$toast.success("Link copiado exitosamente", {
        position: "top-right",
        max: 10,
      });
    },
    copyText() {
      if (this.isMobile) {
        if (navigator.share) {
          navigator
            .share({
              title:
                "XTRIMPRO - Link de referidos de" +
                this.formatText(this.namesList?.[0]) +
                " " +
                this.formatText(this.lastnamesList?.[0]),
              text:
                "Hola soy " +
                this.formatText(this.namesList?.[0]) +
                " " +
                this.formatText(this.lastnamesList?.[0]) +
                " y te invito a formar parte de XtrimPro y ganar dinero refiriendo el internet de Xtrim, Registrate en el siguiente link: ",
              url: "invitacion/" + this.promoCode,
            })
            .then(() => console.log("Successful share"))
            .catch((error) => console.log("Error sharing", error));
        }
      } else {
        // Copy the text inside the text field
        navigator.clipboard.writeText(this.link);

        this.$toast.success("Link Copiado exitosamente", {
          position: "top-right",
          max: 10,
        });
      }
    },
    closed() {
      this.$eventBus.emit("reiniciarFormVentaDashboard");
      this.showModal = false;
    },

    formatText(input) {
      return input.replace(
        /\b\w+/g,
        (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase(),
      );
    },

    closeWithdrawModal() {
      this.$eventBus.emit("ReiniciarRetiroCash");
      this.showModal2 = false;
    },
    copyInputValue(model) {
      const inputElement = document.getElementById(model);
      inputElement.select();
      document.execCommand("copy");
      inputElement.setSelectionRange(0, 0);
      this.$toast.success("Link Copiado exitosamente", {
        position: "top-right",
        max: 10,
      });
    },
    goToRoulette() {
      this.$router.push({ name: "roulette" });
    },
    getBalances() {
      this.loadingBalance = true;
      this.$https
        .post("/profile/getBalance/", {
          tokenAuth: this.tokenAuth,
          userId: this.userId,
        })
        .then((response) => {
          this.loadingBalance = false;
          this.balanceLevel0 = response.data.balance.balanceLevel0;
          this.balanceLevel1 = response.data.balance.balanceLevel1;
          this.balanceLevel2 = response.data.balance.balanceLevel2;
          this.balanceTotal = response.data.balance.balanceTotal;
        })
        .catch((error) => {
          if (error.status === 401) {
            this.$store.dispatch("clearAllData");
            this.$toast.error("Sesión expirada. Inicie sesión nuevamente", {
              position: "top-right",
              max: 10,
            });
            this.$router.push({ name: "Signin" });
          } else if (error.status === 404) {
            this.loadingBalance = false;
            return;
          } else {
            this.$toast.error("Ocurrió un error inesperado", {
              position: "top-right",
              max: 10,
            });
          }
          this.loadingBalance = false;
          // this.getReferidos();
        });
    },
    // Register event listeners
    registerEventListeners() {
      this.$eventBus.on(
        "reiniciarFormVentaDashboard",
        this.reiniciarFormVentaDashboard,
      );
      this.$eventBus.on("ReiniciarRetiroCash", this.ReiniciarRetiroCash);
      this.$eventBus.on("closeModalVenta", this.closeModalVenta);
      this.$eventBus.on("showModalVenta", this.showModalVenta);
      this.$eventBus.on("closeModalRetiro", this.closeModalRetiro);
      this.$eventBus.on("showModalRetiro", this.showModalRetiro);
      this.$eventBus.on("reiniciarListadoVentas", this.reiniciarListadoVentas);
      this.$eventBus.on("reiniciarSaldos", this.reiniciarSaldos);
    },
    // Remove event listeners
    removeEventListeners() {
      this.$eventBus.off(
        "reiniciarFormVentaDashboard",
        this.reiniciarFormVentaDashboard,
      );
      this.$eventBus.off("ReiniciarRetiroCash", this.ReiniciarRetiroCash);
      this.$eventBus.off("closeModalVenta", this.closeModalVenta);
      this.$eventBus.off("showModalVenta", this.showModalVenta);
      this.$eventBus.off("closeModalRetiro", this.closeModalRetiro);
      this.$eventBus.off("showModalRetiro", this.showModalRetiro);
      this.$eventBus.off("reiniciarListadoVentas", this.reiniciarListadoVentas);
      this.$eventBus.off("reiniciarSaldos", this.reiniciarSaldos);
    },

    // Event handler methods
    reiniciarFormVentaDashboard() {
      this.componentKeyFormVenta += 1;
    },
    ReiniciarRetiroCash() {
      this.componentKeyFormRetiro += 1;
    },
    closeModalVenta() {
      this.showModal = false;
    },
    showModalVenta() {
      this.showModal = true;
    },
    closeModalRetiro() {
      this.showModal2 = false;
    },
    showModalRetiro() {
      this.showModal2 = true;
    },
    reiniciarListadoVentas() {
      this.componentKeyListadoVentas += 1;
    },
    reiniciarSaldos() {
      this.getBalances();
    },
  },
  created() {},
  beforeUnmount() {},
  activated() {
    if (this.isMobile) {
      this.$eventBus.emit("activeShowMenu");
    }
  },
  deactivated() {
    console.log("About has been deactivated");
  },

  mounted() {
    // Register the event listeners when the component is mounted
    this.registerEventListeners();
    this.getBalances();
  },
  beforeUnmount() {
    this.removeEventListeners();
  },
};
</script>

<style scoped>
.swiper {
  height: 100% !important;
}
.swiper-slide {
  height: auto !important;
}
.swiper-content {
  height: 100% !important;
}
.swiper-wrapper {
  align-items: stretch;
}

.pinkPurpleText {
  padding: 5px 10px;
  background: rgb(170, 50, 189);
  background: linear-gradient(
    68deg,
    rgba(170, 50, 189, 1) 0%,
    rgb(226, 80, 232) 100%
  );
  border: 2px solid #ec62ea;
  color: white;
  font-family: Meloriac;
  margin: 0;
  width: 100%;
  border-radius: 16px;
  gap: 8px;
  margin: 8px 0;
  letter-spacing: 1.5px;
  box-shadow: 0px 2px 10px 2px #bc49be48;
  font-size: 18px;
  text-align: center;
}
.pinkPurpleText:hover {
  background: linear-gradient(
    68deg,
    rgb(226, 80, 232) 0%,
    rgba(170, 50, 189, 1) 100%
  );
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Apply the animation to the image */
.rotating-image {
  animation: rotate 2s linear infinite; /* Adjust duration and timing */
}
</style>
