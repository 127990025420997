<template>
  <div class="card" style="margin-bottom: 10px">
    <div class="card-body">
      <div class="row">
        <div class="col-10 col-sm-10 col-md-11 col-lg-11 col-xl-11">
          <p style="margin: 10px; font-weight: bold">
            <i style="margin-right: 10px" class="fas fa-list"></i> LISTADO DE
            VENTAS INSTALADAS
          </p>
        </div>

        <div
          class="col-2 col-sm-2 col-md-1 col-lg-1 col-xl-1"
          align="center"
          style="display: flex"
        >
          <Skeletor
            v-if="loading"
            :shimmer="true"
            height="20"
            width="100%"
            style="margin: auto"
          />
          <div v-else @click="refresh">
            <i
              style="cursor: pointer; margin: auto"
              class="fas fa-sync text-sm opacity-10"
            ></i>
          </div>
        </div>
      </div>

      <div
        v-if="loading"
        class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
        style="padding: 0px"
      >
        <div class="row">
          <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
            <Skeletor :shimmer="true" height="40" width="50%" />
          </div>

          <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
            <Skeletor
              :shimmer="true"
              height="10"
              pill
              width="30%"
              style="margin-bottom: 10px"
            />
            <Skeletor :shimmer="true" height="40" width="100%" />
          </div>
          <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <table class="table tablesorter">
              <thead class="thead-light">
                <tr>
                  <th><Skeletor :shimmer="true" height="10" width="100%" /></th>
                  <th><Skeletor :shimmer="true" height="10" width="100%" /></th>
                  <th><Skeletor :shimmer="true" height="10" width="100%" /></th>
                  <th><Skeletor :shimmer="true" height="10" width="100%" /></th>
                  <th><Skeletor :shimmer="true" height="10" width="100%" /></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td><Skeletor :shimmer="true" height="20" width="100%" /></td>
                  <td><Skeletor :shimmer="true" height="20" width="100%" /></td>
                  <td><Skeletor :shimmer="true" height="20" width="100%" /></td>
                  <td><Skeletor :shimmer="true" height="20" width="100%" /></td>
                  <td><Skeletor :shimmer="true" height="20" width="100%" /></td>
                </tr>
                <tr>
                  <td><Skeletor :shimmer="true" height="20" width="100%" /></td>
                  <td><Skeletor :shimmer="true" height="20" width="100%" /></td>
                  <td><Skeletor :shimmer="true" height="20" width="100%" /></td>
                  <td><Skeletor :shimmer="true" height="20" width="100%" /></td>
                  <td><Skeletor :shimmer="true" height="20" width="100%" /></td>
                </tr>
                <tr>
                  <td><Skeletor :shimmer="true" height="20" width="100%" /></td>
                  <td><Skeletor :shimmer="true" height="20" width="100%" /></td>
                  <td><Skeletor :shimmer="true" height="20" width="100%" /></td>
                  <td><Skeletor :shimmer="true" height="20" width="100%" /></td>
                  <td><Skeletor :shimmer="true" height="20" width="100%" /></td>
                </tr>
              </tbody>
            </table>
          </div>

          <div class="col-6 col-sm-6 col-md-8 col-lg-8 col-xl-8">
            <Skeletor :shimmer="true" height="10" pill width="80%" />
          </div>

          <div class="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-4">
            <div class="row">
              <div class="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                <Skeletor :shimmer="true" height="10" width="100%" />
              </div>

              <div
                class="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4"
                align="center"
              >
                <Skeletor :shimmer="true" circle size="30" />
              </div>

              <div class="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                <Skeletor :shimmer="true" height="10" width="100%" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-else-if="notAllow" class="row">
        <div
          class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
          align="center"
        >
          <img style="width: 100px" src="/img/caution.svg" />

          <p style="margin-top: 10px; font-size: 14px">
            Tu rol no te permite utilizar la siguiente funcionalidad.
          </p>
        </div>
      </div>

      <DataTable
        v-else
        :columns="columns"
        :options="options"
        :data="installedSales"
        class="table tablesorter"
        width="100%"
      >
        <thead>
          <tr>
            <th>Actualizado</th>
            <th>Referidor</th>
            <th>Celular</th>
            <th data-priority="1">Nombre</th>
            <th data-priority="2">Compensación</th>
            <th data-priority="2">Canal</th>
            <th data-priority="3">Estado</th>
          </tr>
        </thead>
      </DataTable>
    </div>
  </div>
</template>

<script>
/* eslint-disable */

import DataTable from "datatables.net-vue3";
import DataTablesCore from "datatables.net-bs5";
import "datatables.net-responsive";
import $ from "jquery";

DataTable.use(DataTablesCore);

export default {
  components: {
    DataTable,
  },
  props: ["userId", "tokenAuth", "role"],
  data() {
    return {
      loading: true,
      installedSales: [],
      notAllow: false,
      compensationPlans: [],
      columns: [
        {
          data: null,
          render: function (data) {
            const date = new Date(
              data.updatedOn._seconds * 1000 +
                data.updatedOn._nanoseconds / 1e6,
            );
            return date.toLocaleString("es-ES", {
              timeZone: "America/Guayaquil",
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
              hour: "2-digit",
              minute: "2-digit",
            });
          },
        },
        {
          data: null,
          render: function (data) {
            return `${data.referrerNames} ${data.referrerLastnames}`;
          },
        },
        { data: "phone" },
        {
          data: null,
          render: function (data) {
            return data.displayName;
          },
        },
        {
          data: null,
          render: function (data) {
            const compensation = this.compensationPlans.find((plan) => {
              return plan.planId === data.planId;
            });
            return compensation ? compensation.planName : "N/A";
          }.bind(this),
        },
        {
          data: null,
          render: function (row) {
            if (row.canal === "WhatsApp") {
              return '<span style="background: #28a745; color: white;" class="badge badge-success">WhatsApp</span>';
            } else {
              return '<span style="width:65px" class="badge badge-secondary">Web</span>';
            }
          },
        },
        {
          data: "status",
        },
      ],
      options: {
        responsive: true,
        ordering: false,
        columnDefs: [
          { responsivePriority: 1, targets: 0 },
          { responsivePriority: 2, targets: -1 },
        ],
        lengthMenu: [
          [7, 15, 25, 35, 50, -1],
          [7, 15, 25, 35, 50, "All"],
        ],
        pageLength: 7,
        language: {
          search: "Buscar:",
          sInfo:
            "Mostrando registros del _START_ al _END_ de un total de _TOTAL_ registros",
          sInfoEmpty:
            "Mostrando registros del 0 al 0 de un total de 0 registros",
          searchPlaceholder: "Buscar por término...",
          zeroRecords: "Lo sentimos, no se encontraron resultados",
          processing: "Buscando. Espere por favor...",
          sLengthMenu: "Mostrar _MENU_ registros",
          oPaginate: {
            sFirst: "Primero",
            sLast: "Último",
            sNext: "Siguiente",
            sPrevious: "Anterior",
          },
        },
      },
    };
  },
  computed: {},
  watch: {},
  mounted() {
    this.getListado();
  },
  methods: {
    refresh() {
      $(document).off("click", ".consultar");
      this.$eventBus.emit("reiniciarListadoVentasInstaladasAdmin");
    },

    getListado() {
      this.$https
        .post("/administration/fetchInstalledSales", {
          tokenAuth: this.tokenAuth,
          userId: this.userId,
          role: this.role,
        })
        .then((response) => {
          this.loading = false;
          this.notAllow = false;
          this.installedSales = response.data.installedSales;
          this.compensationPlans = response.data.compensationPlans;
        })
        .catch((error) => {
          if (error.status === 401) {
            this.$store.dispatch("clearAllData");
            this.$toast.error("Sesión expirada. Inicie sesión nuevamente", {
              position: "top-right",
              max: 10,
            });
            this.loading = false;
            this.notAllow = false;
            this.$router.push({ name: "Signin" });
          } else if (error.status === 403) {
            this.loading = false;
            this.$router.push({ name: "Dashboard" });
            this.$toast.error("Acceso no autorizado", {
              position: "top-right",
              max: 10,
            });
          } else {
            this.$toast.error("Ocurrió un error inesperado", {
              position: "top-right",
              max: 10,
            });
            this.loading = false;
            this.notAllow = true;
          }

          // this.getListado();
        });
    },
  },
};
</script>

<style lang="scss">
@import "datatables.net-dt";
@import "datatables.net-responsive-dt";
</style>
