<template>
  <div>
    <div class="row">
      <div class="col-12 col-sm-12 col-md-2 col-lg-3 col-xl-3"></div>
      <div class="col-12 col-sm-12 col-md-8 col-lg-6 col-xl-6">
        <div>
          <div
            style="
              display: flex;
              justify-content: center;
              align-items: center;

              width: 100%;
            "
          >
            <p
              class="mb-0"
              style="
                text-align: center;
                font-family: Meloriac;
                letter-spacing: 1.5px;
                color: #7d0c7e;
                font-size: 18px;
                text-shadow:
                  2px 2px 0px white,
                  -2px -2px 0px white,
                  2px -2px 0px white,
                  -2px 2px 0px white;
                background: #f9cc03;
                border-radius: 12px;
                padding: 3px 12px;
                box-shadow: 0px 2px 10px 2px #7c0c7e48;
              "
            >
              INFORMACIÓN BANCARIA
            </p>
          </div>
          <form role="form" autocomplete="off" @submit.prevent="onSubmitCuenta">
            <div class="card-body">
              <div class="row">
                <div
                  class="col-lg-12 col-12 col-md-12 col-xl-12 col-sm-12"
                  style="margin-bottom: 25px"
                >
                  <label
                    for="example-text-input"
                    class="form-control-label purple"
                    >Institución bancaria</label
                  >
                  <Skeletor
                    v-if="loading"
                    :shimmer="true"
                    height="60"
                    width="100%"
                    style="margin-bottom: 0px"
                  />
                  <v-select
                    v-else
                    placeholder="Seleccione un banco"
                    style="height: 30px"
                    label="Bank"
                    v-model="bank"
                    :disabled="isDisable"
                    :options="banksArray"
                    @option:selected="setSelected"
                  >
                    <template #search="{ attributes, events }">
                      <input
                        class="vs__search"
                        :required="!bankCode"
                        v-bind="attributes"
                        v-on="events"
                      />
                    </template>
                  </v-select>
                </div>

                <div class="col-12" style="margin-bottom: 10px">
                  <label
                    for="example-text-input"
                    class="form-control-label purple"
                    >Tipo de cuenta</label
                  >
                  <Skeletor
                    v-if="loading"
                    :shimmer="true"
                    height="40"
                    width="100%"
                  />
                  <select
                    v-else
                    v-model="type"
                    :disabled="isDisable"
                    class="form-select"
                    placeholder="Seleccione una opción"
                    required
                  >
                    <option selected value="">Seleccione una opción</option>
                    <option value="Ahorro">Ahorro</option>
                    <option value="Corriente">Corriente</option>
                  </select>
                </div>

                <div class="col-12" style="margin-bottom: 10px">
                  <label
                    for="example-text-input"
                    class="form-control-label purple"
                    >Nro. Cuenta bancaria(*)</label
                  >
                  <Skeletor
                    v-if="loading"
                    :shimmer="true"
                    height="40"
                    width="100%"
                  />
                  <input
                    v-else
                    class="form-control"
                    :disabled="isDisable"
                    type="text"
                    placeholder="Ingrese su número de cuenta"
                    v-model="accountNumber"
                    @input="validateNumberInput"
                    required
                  />
                </div>

                <div
                  class="col-lg-12 col-12 col-md-12 col-xl-12 col-sm-12 d-flex justify-content-center mt-3"
                  align="center"
                >
                  <Skeletor
                    v-if="loading"
                    :shimmer="true"
                    pill
                    height="10"
                    width="70%"
                  />
                  <div v-else class="form-check" style="margin-bottom: 10px">
                    <input
                      class="form-check-input form-control"
                      type="checkbox"
                      required
                      v-model="terminos"
                      id="flexCheckDefault"
                      v-on:click="toggleBoton"
                    />
                    <label
                      class="form-check-label"
                      for="flexCheckDefault"
                      style="font-weight: bold; font-size: 11px"
                    >
                      Confirmo que la cuenta bancaria registrada es de mi
                      pertenencia
                    </label>
                  </div>
                </div>

                <div class="col-12 col-sm-12 col-md-12 col-lg-2 col-xl-2"></div>

                <div
                  class="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8"
                  align="center"
                  style="margin-top: 15px; margin-bottom: 15px"
                >
                  <button
                    id="btn_registrar"
                    type="submit"
                    class="btn btn-yellow-normal"
                    :disabled="botonHabilitado"
                    style="width: 100%"
                  >
                    Actualizar cuenta bancaria
                  </button>
                  <p v-if="message" style="margin: 12px 0 0 0; color: red">
                    El número de cuenta solo debe contener números*
                  </p>
                </div>

                <div class="col-12 col-sm-12 col-md-12 col-lg-2 col-xl-2"></div>

                <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                  <div
                    class="alert alert-primary"
                    role="alert"
                    style="margin: 0px; font-size: 12px"
                  >
                    Los datos de la cuenta bancaria a registrarse deben
                    corresponder con la información personal registrada en su
                    perfil de XTRIM.<br />
                    No se aceptan cuentas bancarias de terceras personas, caso
                    contrario, sus solicitudes de retiro de dinero serán
                    rechazadas. <br />Esta cuenta bancaria será usada para el
                    depósito de sus comisiones.
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>

      <div class="col-12 col-sm-12 col-md-2 col-lg-3 col-xl-3"></div>
    </div>
  </div>
</template>

<script>
/*eslint-disable */

export default {
  name: "Banco",
  data() {
    let datos = this.$store.state.user;
    let bankData = this.$store.state.bankData;

    return {
      isDisable: false,
      userId: datos.userId,
      tokenAuth: this.$store.state.tokenAuth,
      userBankAccountId: datos.userBankAccountId,
      isMobile: this.$isMobile,
      loading: true,
      botonHabilitado: true,
      terminos: false,
      banksArray: [],
      bankCode: bankData?.bankCode ?? "",
      accountNumber: bankData?.maskedAccountNumber ?? "",
      type: bankData?.type ?? "",
      bank: bankData?.bank ?? "",
      message: false,
    };
  },
  activated() {
    if (this.isMobile) {
      this.$eventBus.emit("activeShowMenu");
    }
  },
  methods: {
    validateNumberInput() {
      // Remove any non-digit characters
      this.message = false;
      this.accountNumber = this.accountNumber.replace(/\D/g, "");
    },
    clearSelection() {
      this.bankCode = "";
      this.bank = "";
    },
    onSubmitCuenta(event) {
      event.preventDefault();
      if (this.accountNumber.includes("•")) {
        this.message = true;
        return;
      }
      const data_json = {
        userId: this.userId,
        accountNumber: this.accountNumber,
        bank: this.bank,
        bankCode: this.bankCode,
        type: this.type,
      };

      this.isDisable = true;
      this.botonHabilitado = true;

      document.getElementById("btn_registrar").innerHTML =
        '<i class="fas fa-spinner fa-pulse"></i>  Actualizando';

      this.$https
        .post("/banks/registerBankAccount/", {
          tokenAuth: this.tokenAuth,
          ...data_json,
        })
        .then((response) => {
          document.getElementById("btn_registrar").innerHTML =
            "Actualizar cuenta bancaria";
          this.isDisable = false;
          this.botonHabilitado = false;
          this.$toast.success(response.data.message, {
            position: "top-right",
            max: 10,
          });

          this.$store.commit("setBankData", response.data.bankAccount);
          this.$store.commit("setUser", {
            ...this.$store.state.user,
            userBankAccountId: response.data.bankAccount.userBankAccountId,
          });

          this.terminos = false;
          this.botonHabilitado = true;
        })
        .catch((error) => {
          if (error.status === 401) {
            this.$store.dispatch("clearAllData");
            this.$toast.error("Sesión expirada. Inicie sesión nuevamente", {
              position: "top-right",
              max: 10,
            });
            this.$router.push({ name: "Signin" });
          } else {
            this.$toast.error("Ocurrió un error inesperado", {
              position: "top-right",
              max: 10,
            });
            this.isDisable = false;
            this.botonHabilitado = true;
          }
        });
    },

    setSelected(value) {
      this.bankCode = value.Code;
      this.bank = value.Bank;
    },

    toggleBoton() {
      this.botonHabilitado = !this.botonHabilitado;
    },
    loadBanco() {
      this.loading = true;
      this.$https
        .post("/banks/checkHasBankAccount/", {
          tokenAuth: this.tokenAuth,
          userId: this.userId,
          userBankAccountId: this.userBankAccountId,
        })
        .then((response) => {
          this.banksArray = response.data.banks;
          const { hasAccount } = response.data;

          if (hasAccount) {
            this.accountNumber = response.data.bankAccount.maskedAccountNumber;
            this.type = response.data.bankAccount.type;
            this.bankCode = response.data.bankAccount.bankCode;
            this.bank = response.data.bankAccount.bank;
          }

          this.loading = false;
        })
        .catch((error) => {
          if (error.status === 401) {
            this.$store.dispatch("clearAllData");
            this.$toast.error("Sesión expirada. Inicie sesión nuevamente", {
              position: "top-right",
              max: 10,
            });
            this.$router.push({ name: "Signin" });
          } else {
            this.$toast.error("Ocurrió un error inesperado", {
              position: "top-right",
              max: 10,
            });
            this.isDisable = false;
            this.botonHabilitado = true;
          }
          // this.loadBanco();
        });
    },
  },
  mounted() {
    this.loadBanco();
  },
};
</script>

<style scoped>
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.no-spinner::-webkit-inner-spin-button,
.no-spinner::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.vs__search {
  height: 30px;
}

.form-select {
  border: 2px solid #f9cc03;
}

.form-control {
  border: 2px solid #f9cc03;
}

:deep(.vs__dropdown-toggle) {
  background: white;
  border: 2px solid #f9cc03;
  border-radius: 8px;
}

.purple {
  color: #7d0c7e;
  font-size: 14px;
}
</style>
