<template>
  <div
    class="container-fluid menuContainer"
    v-if="userExists"
    style="
      display: flex;
      flex-direction: column;
      width: 100%;
      margin: 0px;
      justify-content: center;
      align-items: center;
      min-height: 100vh;
      height: fit-content;
      gap: 24px;
    "
  >
    <img
      src="../../src/assets/img/Xtrim/Xtrim-Pro-logo.png"
      style="max-width: 300px; width: auto"
    />
    <div
      class="card col-11 col-sm-7 col-md-6 col-lg-5 col-xl-4 col-xxl-4"
      style="padding: 25px"
    >
      <div class="align-items-center text-center">
        <Skeletor
          v-if="loading"
          :shimmer="true"
          height="50"
          width="100%"
          style="margin-bottom: 10px"
        />
      </div>

      <div class="row" v-if="loading">
        <Skeletor
          :shimmer="true"
          height="20"
          width="100%"
          pill
          style="margin-bottom: 10px"
        />

        <div style="margin-bottom: 10px; width: 100%">
          <Skeletor
            :shimmer="true"
            height="20"
            width="50%"
            pill
            style="margin-bottom: 10px"
          />
          <Skeletor
            :shimmer="true"
            height="40"
            width="100%"
            style="margin-bottom: 10px"
          />
        </div>

        <div style="margin-bottom: 10px; width: 100%">
          <Skeletor
            :shimmer="true"
            height="20"
            width="50%"
            pill
            style="margin-bottom: 10px"
          />
          <Skeletor
            :shimmer="true"
            height="40"
            width="100%"
            style="margin-bottom: 10px"
          />
        </div>

        <div style="margin-bottom: 10px; width: 100%">
          <Skeletor
            :shimmer="true"
            height="20"
            width="50%"
            pill
            style="margin-bottom: 10px"
          />
          <Skeletor
            :shimmer="true"
            height="40"
            width="100%"
            style="margin-bottom: 10px"
          />
        </div>

        <div style="margin-bottom: 10px; width: 100%">
          <Skeletor
            :shimmer="true"
            height="20"
            width="50%"
            pill
            style="margin-bottom: 10px"
          />
          <Skeletor
            :shimmer="true"
            height="40"
            width="100%"
            style="margin-bottom: 10px"
          />
        </div>

        <div style="margin-bottom: 10px; width: 100%">
          <Skeletor
            :shimmer="true"
            height="40"
            width="100%"
            style="margin-bottom: 10px"
          />
        </div>
      </div>

      <div v-else-if="welcome" align="center">
        <v-lazy-image
          :src="photo"
          class="rounded-circle"
          @error="photo = '/img/user.svg'"
          style="
            background: white;
            width: 100px;
            margin-bottom: 10px;
            margin-top: 10px;
          "
        />
        <h1
          class="invite-text"
          style="
            margin-bottom: 20px;
            font-size: 20px;
            line-height: 30px;
            text-align: center;
            font-weight: 600;
            color: #7d0c7e;
          "
        >
          ¡Hola! Te saluda
          <span
            style="color: #7d0c7e; font-weight: 700; text-transform: capitalize"
            >{{ formattedName(this.namesList[0], this.lastnamesList[0]) }}
          </span>
          y te quiero invitar a que aproveches esta increíble promoción que te
          ofrece Xtrim, el mejor internet del Ecuador. ¡No te vas a arrepentir!
        </h1>

        <h1
          class="invite-text"
          style="
            margin-bottom: 15px;
            font-size: 20px;
            text-align: center;
            width: 80%;
            color: #7d0c7e;
          "
        >
          ¿Quieres gozar del mejor internet del Ecuador?
        </h1>

        <div style="width: 100%">
          <button
            @click="continuar"
            class="btn btn-yellow-normal"
            style="
              width: 100%;
              font-size: 20px;
              text-align: center;
              font-weight: 900;
            "
          >
            ¡Sí quiero! 😀
          </button>
        </div>
      </div>

      <form-venta
        :key="componentKeyFormVenta"
        v-else
        :promo-code="promoCode"
        :token-auth="tokenAuth"
        :on-dashboard="false"
      />
    </div>

    <white-footer-component />
  </div>

  <div v-else class="row" style="width: 100%; margin: 0px">
    <not-promotor />
  </div>
</template>

<script>
/*eslint-disable */

import firebase from "firebase/compat/app";
import "firebase/compat/app-check";
import NotPromotor from "./NotPromotor";
import FormVenta from "./FormVenta";
import WhiteFooterComponent from "./Layouts/WhiteFooter.vue";

export default {
  name: "Venta",
  components: {
    FormVenta,
    NotPromotor,
    WhiteFooterComponent,
  },
  data() {
    let promoCode = this.$route.params.promoCode;

    return {
      userExists: true,
      year: new Date().getFullYear(),
      appCheck: null,
      tokenAuth: this.$store.state.tokenAuth,
      loading: true,
      photo: "",
      promoCode: promoCode,
      componentKeyFormVenta: 0,
      displayName: "",
      namesList: [],
      lastnamesList: [],
      welcome: false,
      textAyuda:
        "https://api.whatsapp.com/send?phone=593995756176&text=Hola,%20deseo%20obtener%20más%20información%20de%20XTRIMPRO",
    };
  },

  methods: {
    continuar() {
      this.welcome = false;
    },

    getInfo() {
      this.loading = true;

      this.$https
        .post("/links/getUserByPromoCode/", { promoCode: this.promoCode })
        .then(async (response) => {
          if (response.data.code == 200) {
            if (response.data.photo === "") {
              this.photo = "/img/user.svg";
            } else {
              this.photo = response.data.photo;
            }
            this.displayName = response.data.displayName;
            this.namesList = response.data.namesList;
            this.lastnamesList = response.data.lastnamesList;

            document.title =
              "XtrimPro - Link de venta de " +
              this.formattedName(this.namesList[0], this.lastnamesList[0]);

            this.loading = false;
            this.welcome = true;
          } else {
            if (response.data.code == 404) {
              this.$toast.error(response.data.message, {
                position: "top-right",
                max: 10,
              });

              this.userExists = false;
            } else {
              this.getInfo();
            }
          }
        })
        .catch((error) => {
          console.log("error", error);
          // this.getInfo();
        });
    },
    formattedName(names, lastnames) {
      const formatName = (name) => {
        return name.charAt(0).toUpperCase() + name.slice(1).toLowerCase();
      };

      const firstName = formatName(names);
      const lastName = formatName(lastnames);

      return `${firstName} ${lastName}`;
    },
  },
  computed: {},
  watch: {},
  mounted() {
    document.title = "XtrimPro - Link de venta ";
    this.getInfo();

    this.$eventBus.on("reiniciarFormVenta", () => {
      this.componentKeyFormVenta += 1;
    });
  },
  created() {},
  beforeUnmount() {},
};
</script>

<style scoped>
.container-fluid {
  padding: 0px;
  margin: 0px;
}

.bg-dark {
  background: linear-gradient(87deg, #591361, #7d0c7e) !important;
  height: 160px;
}
.menuContainer {
  display: flex;
  min-height: 100vh;
  height: 100%;
  justify-content: center;
  align-items: center;
  min-height: calc(var(--vh, 1vh) * 100);
  margin: 0px;
  padding: 0;
  background-color: #7d0c7e !important;
  background: url("../../src/assets/img/Xtrim/register-background.png")
    no-repeat center center;
  background-size: cover;
  /* @media (max-width: 1199px) {
  } */
}
</style>
