<template>
  <div class="header-background">
    <div class="header-content">
      <div class="logo">
        <img
          src="../../assets/img/Landing/xtrim-pro-logo.png"
          alt="XtrimPro Logo"
        />
        <login-ingreso-m />
      </div>

      <!-- Mi XtrimPro y Menú -->
      <div class="menu">
        <ul>
          <!-- <li><a href="#">INICIO ✨</a></li> -->
          <li><a href="/login">REGÍSTRATE ✨</a></li>
          <li><a href="#como-ganar">¿CÓMO GANAR? ✨</a></li>
          <!-- <li><a href="#">MAPA DE COBERTURA ✨</a></li> -->
          <li><a href="#preguntas">PREGUNTAS FRECUENTES ✨</a></li>
          <li><a href="#contacto">CONTÁCTANOS ✨</a></li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import LoginIngresoM from "./LoginMobile.vue";

export default {
  name: "Landing-Mobile",
  components: {
    LoginIngresoM,
  },
  data() {
    return {};
  },
};
</script>

<style scoped>
/* Fondo principal */
.header-background {
  background-image: url("../../assets/img/Landing/bloque-superior-web.png");
  background-size: cover;
  background-position: center;
  width: 100%;
  height: auto;
  padding: 20px 20px 20px 0;
}

/* Contenedor del contenido */
.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

/* Estilos del logo */
.logo img {
  height: 50px;
  padding-left: 20px;
}

.menu {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  /* Alineación del menú a la derecha */
  gap: 10px;
}

/* Estilos de "Mi XtrimPro" */
.mi-xtrimpro {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-bottom: 10px;
}

.mi-xtrimpro .profile-icon img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.mi-xtrimpro .profile-text {
  font-size: 14px;
  font-weight: bold;
  color: #fff;
}

/* Menú de navegación */
.menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  /* Enlaces alineados a la derecha */
  gap: 10px;
}

.menu ul li {
  position: relative;
  /* Para posicionar el ::after */
}

.menu ul li a {
  font-size: 12px;
  color: #fff;
  text-decoration: none;
  font-weight: bold;
  padding-bottom: 5px;
  /* Espacio para el efecto hover */
  transition: color 0.3s ease;
}

/* Efecto de hover con la línea amarilla */
.menu ul li a::after {
  content: "";
  /* Línea decorativa */
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px;
  /* Grosor de la línea */
  background-color: yellow;
  transition: width 0.3s ease;
  /* Animación */
}

.menu ul li a:hover::after {
  width: 100%;
  /* Línea se expande al 100% */
}

.menu ul li a:hover {
  color: yellow;
  /* Cambiar color del texto si se desea */
}
</style>
