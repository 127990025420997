<template>
  <div class="rouletteParentContainer">
    <img src="../assets/img/Xtrim/gira-la-ruleta.png" class="firstTitle" />
    <img src="../assets/img/Xtrim/obten-un-bono.png" class="secondTitle" />
    <div
      style="display: flex; justify-content: space-between; align-items: center"
    >
      <p class="pinkPurpleText">Haz click en la ruleta</p>
    </div>
    <div class="roulette-container">
      <img
        src="../assets/img/Xtrim/manito-izq.png"
        style="position: absolute; top: 0; left: 0"
        class="scaling-image"
      />
      <img
        src="../assets/img/Xtrim/arrow-gold.png"
        class="gold-arrow"
        :style="{ width: arrowSize + 'px', height: auto, top: arrowTop + 'px' }"
      />
      <img
        src="../assets/img/Xtrim/manito-der.png"
        style="position: absolute; top: 0; right: 0"
        class="scaling-image"
      />
      <div class="roulette-purple">
        <div class="roulette-gold">
          <Roulette
            :key="wheelKey"
            class="custom-wheel"
            ref="wheel"
            :items="items"
            @click="launchWheel"
            @wheel-start="wheelStartedCallback"
            @wheel-end="wheelEndedCallback"
            :display-indicator="true"
            :base-display-indicator="false"
            :centered-indicator="false"
            :base-display="true"
            :display-border="false"
            :horizontal-content="true"
            :base-display-shadow="true"
            :display-shadow="false"
            easing="ease"
            :size="wheelSize"
            base-background="#0e4cdd"
            base-size="30"
            :duration="wheelSettings.duration"
            :first-item-index="wheelSettings.firstItemIndex"
            :wheel-result-index="wheelValue"
            ><template #baseContent>
              <div
                class="arrow-roullete"
                v-html="wheelSettings.baseHtmlContent"
              /> </template
          ></Roulette>
        </div>
      </div>
      <div class="pinkPurpleDiv">
        <p class="whiteText">OPORTUNIDADES</p>
        <div class="yellowSquare">
          <p class="purpleText">
            {{ availableSpins }}
          </p>
        </div>
      </div>
    </div>
    <!-- <p class="promotionalBanner">
      ¿QUIERES
      <span style="color: #f9cc03">$10</span>
      FACILITO
      <br />
      ¡DA CLICK Y HAZTE PRO!
    </p> -->
  </div>
</template>

<script>
/*eslint-disable */
import { Roulette } from "vue3-roulette";
import { ref, onMounted, onUnmounted, inject } from "vue";
import winnerBanner from "@/assets/img/Xtrim/cada-vez-mas-pro.png";
import almostBanner from "@/assets/img/Xtrim/casi-lo-logras.png";
import luckBanner from "@/assets/img/Xtrim/sera-la-proxima.png";
import prize from "@/assets/img/Xtrim/premio.png";
import handRight from "@/assets/img/Xtrim/manito1.png";
import handLeft from "@/assets/img/Xtrim/manito2.png";

export default {
  name: "RouletteDashboard",
  components: { Roulette },
  emits: ["wheel-start", "wheel-end"], // Declare emitted events
  setup() {
    const $swal = inject("$swal");
    const $https = inject("https");
    const $store = inject("store");
    const $toast = inject("toast");
    // console.log("toast", $toast);

    const wheel = ref(null); // Define the roulette wheel reference
    const wheelKey = ref(0);
    const wheelSize = ref(300);
    const arrowSize = ref(125); // Initial size for the arrow
    const arrowTop = ref(5); // Initial size for the arrow
    const rand = Math.floor(Math.random() * 12) + 1;
    const availableSpins = ref(0);
    const wheelValue = ref({ value: Math.floor(Math.random() * 12) });
    const sales = ref(0); // The sales count (from your data)
    const hasWon = ref(false); // Start by assuming the user hasn't won yet

    const wheelSettings = {
      centeredIndicator: true,
      indicatorPosition: "top",
      displayShadow: true,
      duration: 5,
      resultVariation: 70,
      easing: "ease",
      counterClockwise: true,
      horizontalContent: false,
      displayBorder: true,
      displayIndicator: true,
      baseDisplay: true,
      baseSize: 210,
      baseDisplayShadow: false,
      baseDisplayIndicator: true,
      baseBackground: "",
      baseHtmlContent: "",
      firstItemIndex: rand,
    };
    const items = ref([
      {
        id: 1,
        name: "Un dólar",
        htmlContent: `<span class="money-text">$1.00💵</span>`,
        textColor: "#4f178c",
        background:
          "linear-gradient(68deg, rgba(255,255,255,1) 0%, rgba(235,235,235,1) 100%)",
        value: 1,
      },
      {
        id: 2,
        name: "Casi lo logras",
        htmlContent: "¡Casi lo logras!",
        textColor: "#ffffff",
        background:
          "linear-gradient(68deg, rgba(114,80,186,1) 0%, rgba(78,20,140,1) 100%)",
        value: null,
      },
      {
        id: 3,
        name: "Cinco dólares",
        htmlContent: `<span class="money-text">$5.00🤑</span>`,
        textColor: "#4f178c",
        background:
          "linear-gradient(68deg, rgba(255,255,255,1) 0%, rgba(235,235,235,1) 100%)",
        value: 5,
      },
      {
        id: 4,
        name: "Será la próxima",
        htmlContent: "¡Será la próxima!",
        textColor: "#ffffff",
        background:
          "linear-gradient(68deg, rgba(114,80,186,1) 0%, rgba(78,20,140,1) 100%)",
        value: null,
      },
      {
        id: 5,
        name: "Dos dólares",
        htmlContent: `<span class="money-text">$2.00💸</span>`,
        textColor: "#4f178c",
        background:
          "linear-gradient(68deg, rgba(255,255,255,1) 0%, rgba(235,235,235,1) 100%)",
        value: 2,
      },
      {
        id: 6,
        name: "Casi lo logras",
        htmlContent: "¡Casi lo logras!",

        textColor: "#ffffff",
        background:
          "linear-gradient(68deg, rgba(114,80,186,1) 0%, rgba(78,20,140,1) 100%)",
        value: null,
      },
      {
        id: 7,
        name: "Diez dólares",
        htmlContent: `<span class="money-text">$10.00💰</span>`,
        textColor: "#4f178c",
        background:
          "linear-gradient(68deg, rgba(255,255,255,1) 0%, rgba(235,235,235,1) 100%)",
        value: 10,
      },
      {
        id: 8,
        name: "Será la próxima",
        htmlContent: "¡Será la próxima!",
        textColor: "#ffffff",
        background:
          "linear-gradient(68deg, rgba(114,80,186,1) 0%, rgba(78,20,140,1) 100%)",
        value: null,
      },
      {
        id: 9,
        name: "Dos dólares",
        htmlContent: `<span class="money-text">$2.00💸</span>`,
        textColor: "#4f178c",
        background:
          "linear-gradient(68deg, rgba(255,255,255,1) 0%, rgba(235,235,235,1) 100%)",
        value: 2,
      },
      {
        id: 10,
        name: "Casi lo logras",
        htmlContent: "¡Casi lo logras!",
        textColor: "#ffffff",
        background:
          "linear-gradient(68deg, rgba(114,80,186,1) 0%, rgba(78,20,140,1) 100%)",
        value: null,
      },
      {
        id: 11,
        name: "Un dólar",
        htmlContent: `<span class="money-text">$1.00💵</span>`,
        textColor: "#4f178c",
        background:
          "linear-gradient(68deg, rgba(255,255,255,1) 0%, rgba(235,235,235,1) 100%)",
        value: 1,
      },
      {
        id: 12,
        name: "Será la próxima",
        htmlContent: "¡Será la próxima!",
        textColor: "#ffffff",
        background:
          "linear-gradient(68deg, rgba(114,80,186,1) 0%, rgba(78,20,140,1) 100%)",
        value: null,
      },
    ]);

    const launchWheel = () => {
      if (wheel.value) {
        if (availableSpins.value <= 0) {
          $toast.error("No tienes oportunidades disponibles", {
            position: "top-right",
            max: 10,
          });
          return;
        } else if (
          sales.value === 0 &&
          availableSpins.value > 0 &&
          availableSpins.value <= 3
        ) {
          // Check if the current spin results in a win
          if (wheelValue.value.value % 2 === 0 && !hasWon.value) {
            hasWon.value = true;
          }

          // Determine the next spin's value
          let nextIndex = Math.floor(Math.random() * 12);

          // If it's the last spin and the user hasn't won, ensure a win
          if (!hasWon.value && availableSpins.value === 2) {
            while (nextIndex % 2 !== 0) {
              nextIndex = Math.floor(Math.random() * 12); // Force an even number
            }
            hasWon.value = true;
          }
          // If the user has already won, make sure the next spin is a losing one
          else if (hasWon.value && nextIndex % 2 === 0) {
            while (nextIndex % 2 !== 1) {
              nextIndex = Math.floor(Math.random() * 12); // Force an odd number
            }
          }

          // Set the next value for the upcoming spin
          wheelValue.value = { value: nextIndex };
        } else {
          wheelValue.value = { value: Math.floor(Math.random() * 12) }; // Random value from 0 to 11
        }
        wheel.value.launchWheel(); // Call the launchWheel method
      } else {
        console.error("The wheel reference is not set yet.");
      }
    };

    const wheelStartedCallback = () => {
      $https
        .post("/banks/deductSpin", {
          tokenAuth: $store.state.tokenAuth,
          userId: $store._state.data.user.userId,
        })
        .then((response) => {
          // Replace this with your API call or Firebase query
          availableSpins.value = response.data.remainingSpins;
        })
        .catch((error) => {
          console.log("Error:", error);
        });
    };
    const wheelEndedCallback = () => {
      if (wheel.value.itemSelected.value) {
        $swal
          .fire({
            html: `<div class="prizeDiv">
            <img src="${prize}" class="crownStyle"/>
            <p class="whiteTextPrize">Ganaste $${
              wheel.value.itemSelected.value
                ? wheel.value.itemSelected.value.toFixed(2)
                : Number(0).toFixed(2)
            }</p></div>`,
            confirmButtonText: "CANJEAR PREMIO",
            showCancelButton: false,
            cancelButtonText: "Close",
            background:
              "linear-gradient(68deg, rgb(197,30,177) 0%, rgb(173,40,156)",
            imageUrl: winnerBanner,
            imageWidth: "100%",
            imageHeight: "auto",
            imageAlt: "Custom image",
            allowOutsideClick: false,
            // backdrop: `rgba(0,0,123,0.4)`,
            customClass: {
              confirmButton: "btn btn-yellow",
              popup: "popupStyle",
            },
            buttonsStyling: false,
          })
          .then((result) => {
            if (result.isConfirmed) {
              $https
                .post("/banks/rewardUser", {
                  tokenAuth: $store.state.tokenAuth,
                  userId: $store._state.data.user.userId,
                  prize: wheel.value.itemSelected.value,
                  names: $store._state.data.user.names,
                  lastnames: $store._state.data.user.lastnames,
                  phone: $store._state.data.user.phone,
                  email: $store._state.data.user.email,
                })
                .then((response) => {
                  $toast.success(response.data.message, {
                    position: "top-right",
                    max: 10,
                  });
                })
                .catch((error) => {
                  if (error.response.data.code === 400) {
                    $toast.error("No tienes permitido girar la ruleta", {
                      position: "top-right",
                      max: 10,
                    });
                  } else {
                    $toast.error("Ocurrió un error inesperado", {
                      position: "top-right",
                      max: 10,
                    });
                  }
                });
            } else if (result.isDenied) {
              console.log("User denied action");
            } else if (result.isDismissed) {
              console.log("User dismissed the modal");
            }
          })
          .finally(() => {
            resetWheel();
          });
      } else {
        let banner =
          wheel.value.itemSelected.name === "Será la próxima"
            ? luckBanner
            : almostBanner;
        let icon =
          wheel.value.itemSelected.name === "Será la próxima"
            ? handRight
            : handLeft;
        $swal
          .fire({
            html: `<div class="prizeDiv">
              <img src="${icon}" class="crownStyle"/>
            </div>`,
            confirmButtonText: "ACEPTAR",
            showCancelButton: false,
            cancelButtonText: "Close",
            background:
              "linear-gradient(68deg, rgb(197,30,177) 0%, rgb(173,40,156)",
            imageUrl: banner,
            imageWidth: "100%",
            imageHeight: "auto",
            imageAlt: "Custom image",
            allowOutsideClick: false,
            // backdrop: `rgba(0,0,123,0.4)`,
            customClass: {
              confirmButton: "btn btn-yellow",
              popup: "popupStyle",
            },
            buttonsStyling: false,
          })
          .then((result) => {
            if (result.isConfirmed) {
              $https
                .post("/banks/blockUserSpins", {
                  tokenAuth: $store.state.tokenAuth,
                  userId: $store._state.data.user.userId,
                })
                .then((response) => {
                  console.log("No more spins");
                });
            }
          })
          .finally(() => {
            resetWheel();
          });
      }
    };

    function getWheelSize(screenWidth) {
      if (screenWidth > 1200) return 430;
      if (screenWidth > 991) return 410;
      if (screenWidth > 800) return 430;
      if (screenWidth > 600) return 430;
      if (screenWidth > 400) return 320;
      if (screenWidth > 330) return 270;
      return 230; // Small size for mobile
    }

    const getArrowSize = (screenWidth) => {
      if (screenWidth > 1200) return 138; // Large screen size for the arrow
      if (screenWidth > 991) return 134; // Medium screen size
      if (screenWidth > 800) return 138; // Medium screen size
      if (screenWidth > 600) return 136; // Medium screen size
      if (screenWidth > 400) return 120; // Medium screen size
      if (screenWidth > 330) return 120; // Medium screen size
      return 118; // Small screen size for mobile
    };

    const getArrowTop = (screenWidth) => {
      if (screenWidth > 1200) return 6; // Large screen size for the arrow
      if (screenWidth > 991) return 7; // Medium screen size
      if (screenWidth > 800) return 6; // Medium screen size
      if (screenWidth > 600) return 7; // Medium screen size
      if (screenWidth > 400) return 10; // Medium screen size
      if (screenWidth > 330) return 10; // Medium screen size
      return 11; // Small screen size for mobile
    };

    // Update wheel size on resize
    const handleResize = () => {
      wheelSize.value = getWheelSize(window.innerWidth);
      arrowSize.value = getArrowSize(window.innerWidth);
      arrowTop.value = getArrowTop(window.innerWidth);
    };

    const fetchAvailableSpins = async () => {
      $https
        .post("/banks/fetchAvailableSpins", {
          tokenAuth: $store.state.tokenAuth,
          userId: $store._state.data.user.userId,
        })
        .then((response) => {
          availableSpins.value = response.data.spins; // Update the available spins
          sales.value = response.data.sales; // Update the available spins
        })
        .catch((error) => {
          console.log("Error:", error);
        });
    };

    const resetWheel = () => {
      wheelKey.value += 1; // Increment key to reset the wheel
      // console.log("Wheel reset with key:", wheelKey);
    };

    onMounted(() => {
      // Set initial size and add event listener for resize
      handleResize();
      fetchAvailableSpins();
      window.addEventListener("resize", handleResize);
    });

    onUnmounted(() => {
      // Clean up event listener
      window.removeEventListener("resize", handleResize);
    });

    return {
      wheel,
      items,
      wheelSettings,
      wheelSize,
      arrowSize,
      arrowTop,
      wheelKey,
      availableSpins,
      wheelValue,
      launchWheel,
      wheelStartedCallback,
      wheelEndedCallback,
      getArrowSize,
      getArrowTop,
      resetWheel,
      fetchAvailableSpins,
    };
  },
  methods: {},
  activated() {
    if (this.isMobile) {
      this.$eventBus.emit("inactiveShowMenu");
    }
  },
  mounted() {},
};
</script>

<style>
.rouletteParentContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.gold-arrow {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
  transition:
    width 0.3s,
    height 0.3s;
}
.roulette-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px 20px 0 20px;
  position: relative;
}
.roulette-purple {
  border: 20px solid #820e82 !important;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 5px 5px 15px -5px #000;
}
.roulette-gold {
  background: radial-gradient(
      ellipse farthest-corner at right bottom,
      #fedb37 0%,
      #fdb931 8%,
      #9f7928 30%,
      #8a6e2f 40%,
      transparent 80%
    ),
    radial-gradient(
      ellipse farthest-corner at left top,
      #ffffff 0%,
      #ffffac 8%,
      #d1b464 25%,
      #5d4a1f 62.5%,
      #5d4a1f 100%
    );
  padding: 5px;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.custom-wheel .wheel-base-indicator::before {
  left: 50% !important;
}
.wheel-container-indicator::before {
  left: 50% !important;
  border-top: 20px solid #ceb263 !important;
}
.wheel-item {
  border: none !important;
}

.custom-wheel .wheel-border::after {
  background-image: none;
}

.custom-wheel .wheel-base-container {
  border: none !important;
}

.content.horizontal-content span {
  /* Your styles for the span inside the div */
  font-size: 14px;
  font-family: "Meloriac", sans-serif;
  letter-spacing: 1.5px;
  /* margin-left: 24px !important; Example style */
}

.money-text {
  font-size: 30px !important;
  letter-spacing: 1.5px;
}

/* Media Queries for Font Size Adjustments */
@media screen and (min-width: 1201px) {
  .content.horizontal-content span {
    font-size: 15px;
  }
  .money-text {
    font-size: 18px !important;
  }
}

@media screen and (min-width: 992px) and (max-width: 1200px) {
  .content.horizontal-content span {
    font-size: 15px;
  }
  .money-text {
    font-size: 20px !important;
  }
}

@media screen and (min-width: 801px) and (max-width: 991px) {
  .content.horizontal-content span {
    font-size: 15px;
  }
  .money-text {
    font-size: 18px !important;
  }
}

@media screen and (min-width: 601px) and (max-width: 800px) {
  .content.horizontal-content span {
    font-size: 14px;
  }
  .money-text {
    font-size: 20px !important;
  }
}

@media screen and (max-width: 600px) {
  .content.horizontal-content span {
    font-size: 12px;
  }
  .money-text {
    font-size: 16px !important;
  }
}
@media screen and (max-width: 400px) {
  .content.horizontal-content span {
    font-size: 10px;
  }
  .money-text {
    font-size: 12px !important;
  }
}

@media screen and (max-width: 330px) {
  .content.horizontal-content span {
    font-size: 8px;
  }
  .money-text {
    font-size: 10px !important;
  }
}

.wheel .content.horizontal-content span {
  width: max-content !important;
  text-align: -webkit-center !important;
}

.wheel .content.horizontal-content {
  display: flex !important;
  align-items: flex-start !important;
  justify-content: center !important;
  right: 86% !important;
  padding: 0 !important;
}

.pinkPurpleDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 10px;
  background: rgb(170, 50, 189);
  background: linear-gradient(
    68deg,
    rgba(170, 50, 189, 1) 0%,
    rgb(226, 80, 232) 100%
  );
  border: 2px solid #ec62ea;
  color: white;
  font-family: Meloriac;
  margin: 0;
  width: max-content;
  border-radius: 16px;
  gap: 8px;
  align-self: flex-end;
  margin-top: 16px;
}

.pinkPurpleText {
  padding: 5px 10px;
  background: rgb(170, 50, 189);
  background: linear-gradient(
    68deg,
    rgba(170, 50, 189, 1) 0%,
    rgb(226, 80, 232) 100%
  );
  border: 2px solid #ec62ea;
  color: white;
  font-family: Meloriac;
  margin: 0;
  width: max-content;
  border-radius: 16px;
  gap: 8px;
  margin-top: 8px;
  letter-spacing: 1.5px;
}

.whiteText {
  margin: 0;
  color: white;
  font-family: Meloriac;
  font-size: 16px;
}

.whiteTextPrize {
  margin: 0;
  color: white;
  font-family: Meloriac;
  font-size: 24px;
  padding: 5px 5px;
  border-radius: 8px;
  letter-spacing: 1.5px;
}

.yellowSquare {
  display: flex;
  padding: 0 5px;
  justify-content: center;
  align-items: center;
  min-width: 24px;
  background-color: #f9cc03;
  border: 1px solid white;
  border-radius: 7px;
  aspect-ratio: 1/1;
}

.purpleText {
  margin: 0;
  color: #7d0c7e;
  font-family: Meloriac;
  font-size: 16px;
}

.prizeDiv {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.crownStyle {
  height: 120px;
  margin: -57px 0 0 0;
}
.popupStyle {
  border: 6px solid #da31da !important;
  border-radius: 18px !important;
}
</style>

<style scoped>
.swal2-html-container {
  margin-top: 0 !important;
}

.swal2-image {
  margin-bottom: 0 !important;
}

.firstTitle {
  max-width: 430px;
  width: 100%;
}

.secondTitle {
  margin-top: -15px;
  max-width: 430px;
  width: 100%;
}

.promotionalBanner {
  padding: 5px 10px;
  background: rgb(170, 50, 189);
  background: linear-gradient(
    68deg,
    rgba(170, 50, 189, 1) 0%,
    rgba(217, 76, 225, 1) 100%
  );
  border: 1px solid #ec62ea;
  font-size: 18px;
  color: white;
  font-family: Meloriac;
  margin: 16px 0;
  letter-spacing: 1.5px;
  width: auto;
  border-radius: 20px;
}

@media screen and (max-width: 480px) {
  .firstTitle {
    width: 70%;
  }
  .secondTitle {
    width: 70%;
    margin-top: -10px;
  }
}
@keyframes scaleAnimation {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.15);
  }
  100% {
    transform: scale(1);
  }
}

.scaling-image {
  animation: scaleAnimation 1.5s ease-in-out infinite;
}
</style>
