/* eslint-disable */
export default function createPersistedStatePlugin() {
  return (store) => {
    Object.keys(store.state).forEach((key) => {
      const storedValue = localStorage.getItem(key);
      if (storedValue) {
        try {
          // Try parsing it as JSON, if possible
          const parsedValue = isJson(storedValue)
            ? JSON.parse(storedValue)
            : storedValue;
          store.commit(
            `set${key.charAt(0).toUpperCase()}${key.slice(1)}`,
            parsedValue
          );
        } catch (e) {
          console.error(`Error parsing ${key} from localStorage:`, e);
        }
      }
    });

    // Subscribe to mutations and store state in localStorage
    store.subscribe((mutation, state) => {
      // If the mutation type matches any state key, persist it
      if (mutation.type.startsWith("set")) {
        const key = `${mutation.type
          .slice(3)
          .charAt(0)
          .toLowerCase()}${mutation.type.slice(4)}`;

        if (Object.prototype.hasOwnProperty.call(state, key)) {
          try {
            const valueToStore = state[key];
            // Only store if it's an object or array that needs to be stringified
            if (typeof valueToStore === "object" && valueToStore !== null) {
              localStorage.setItem(key, JSON.stringify(valueToStore));
            } else {
              localStorage.setItem(key, valueToStore);
            }
          } catch (e) {
            console.error(`Error saving ${key} to localStorage:`, e);
          }
        }
      }
    });
  };
}

function isJson(value) {
  try {
    JSON.parse(value);
    return true;
  } catch (e) {
    return false;
  }
}
