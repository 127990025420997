/* eslint-disable */

import { register } from "register-service-worker";

if (process.env.NODE_ENV === "production") {
  register(`${process.env.BASE_URL}firebase-messaging-sw.js`, {
    ready() {
      console.log(
        "App is being served from cache by a service worker.\nFor more details, visit https://goo.gl/AFskqB"
      );
    },
    registered(reg) {
      console.log("Service worker has been registered.");
      setInterval(
        () => {
          reg.update();
        },
        1000 * 60 * 60
      ); // e.g. hourly checks
    },
    cached() {
      console.log("Content has been cached for offline use.");
    },
    updatefound() {
      console.log("New content is downloading.");
    },
    updated(reg) {
      console.log("New content is available; determining context...");

      // Detect if the app is running as a PWA
      const isInPWA = window.matchMedia("(display-mode: standalone)").matches;

      if (!isInPWA) {
        console.log("App is running in browser; reloading...");
        if (reg.waiting) {
          reg.waiting.postMessage({ action: "skipWaiting" });

          reg.waiting.addEventListener("statechange", (event) => {
            if (event.target.state === "activated") {
              window.location.reload();
            }
          });
        }
      } else {
        console.log("App is running as a PWA; skipping auto-reload.");
        // Optionally notify the user about the update
        document.dispatchEvent(
          new CustomEvent("pwaUpdateAvailable", { detail: reg.waiting })
        );
      }
      // console.log("New content is available; please refresh.");
      // document.dispatchEvent(
      //   new CustomEvent("swUpdated", { detail: reg.waiting })
      // );
    },
    offline() {
      console.log(
        "No internet connection found. App is running in offline mode."
      );
    },
    error(error) {
      console.error("Error during service worker registration:", error);
    },
  });
}
