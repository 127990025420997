<template>
  <Carousel :autoplay="6800" :wrap-around="true">
    <Slide key="index1">
      <div class="carousel__item">
        <img :src="photo" />
      </div>
    </Slide>
    <Slide key="index2">
      <div class="carousel__item">
        <img :src="photo2" />
      </div>
    </Slide>
    <Slide key="index3">
      <div class="carousel__item">
        <img :src="photo3" />
      </div>
    </Slide>
  </Carousel>
</template>

<script>
import { Carousel, Slide } from "vue3-carousel";
import "vue3-carousel/dist/carousel.css";

export default {
  name: "Autoplay",
  components: {
    Carousel,
    Slide,
  },
  data() {
    return {
      photo: require("@/assets/img/Landing/banner1.webp"),
      photo2: require("@/assets/img/Landing/banner2.webp"),
      photo3: require("@/assets/img/Landing/banner3.webp"),
    };
  },
};
</script>

<style>
.carousel__viewport {
  display: flex;
  overflow: hidden;
}

.carousel__item img {
  height: auto !important; /* Maintain aspect ratio and ensure image resizes */
  object-fit: cover !important; /* Ensure image covers container without distortion */
  border-radius: 5px !important;
  background: transaparent;
  width: 80%;
}

@media screen and (max-width: 600px) {
  .carousel__item img {
    background: transaparent;
    width: 100%;
  }
}
</style>
